import * as React from "react"
import Svg, { Path } from "react-native-svg"

const DashboardIcon = (props) => (
    <Svg
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 42 42"
        {...props}
    >
        <Path d="M13.75 20h-7.5A6.27 6.27 0 0 1 0 13.75v-7.5A6.27 6.27 0 0 1 6.25 0h7.5A6.27 6.27 0 0 1 20 6.25v7.5A6.27 6.27 0 0 1 13.75 20ZM6.25 2A4.25 4.25 0 0 0 2 6.25v7.5A4.25 4.25 0 0 0 6.25 18h7.5A4.25 4.25 0 0 0 18 13.75v-7.5A4.25 4.25 0 0 0 13.75 2Zm29.5 18h-7.5A6.27 6.27 0 0 1 22 13.75v-7.5A6.27 6.27 0 0 1 28.25 0h7.5A6.27 6.27 0 0 1 42 6.25v7.5A6.27 6.27 0 0 1 35.75 20Zm-7.5-18A4.25 4.25 0 0 0 24 6.25v7.5A4.25 4.25 0 0 0 28.25 18h7.5A4.25 4.25 0 0 0 40 13.75v-7.5A4.25 4.25 0 0 0 35.75 2Zm-14.5 40h-7.5A6.27 6.27 0 0 1 0 35.75v-7.5A6.27 6.27 0 0 1 6.25 22h7.5A6.27 6.27 0 0 1 20 28.25v7.5A6.27 6.27 0 0 1 13.75 42Zm-7.5-18A4.25 4.25 0 0 0 2 28.25v7.5A4.25 4.25 0 0 0 6.25 40h7.5A4.25 4.25 0 0 0 18 35.75v-7.5A4.25 4.25 0 0 0 13.75 24Zm29.5 18h-7.5A6.27 6.27 0 0 1 22 35.75v-7.5A6.27 6.27 0 0 1 28.25 22h7.5A6.27 6.27 0 0 1 42 28.25v7.5A6.27 6.27 0 0 1 35.75 42Zm-7.5-18A4.25 4.25 0 0 0 24 28.25v7.5A4.25 4.25 0 0 0 28.25 40h7.5A4.25 4.25 0 0 0 40 35.75v-7.5A4.25 4.25 0 0 0 35.75 24Z" />
    </Svg>
)

export default DashboardIcon
