import * as React from "react"
import Svg, { Path } from "react-native-svg"

const AlertIcon = (props) => (
    <Svg
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 50 50"
        {...props}
    >
        <Path d="M17 42h2a6 6 0 0 0 12 0h2a8 8 0 0 1-16 0Zm33-4v1a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3v-1a6 6 0 0 1 6-6V19a19 19 0 0 1 38 0v13a6 6 0 0 1 6 6Zm-6-4a2 2 0 0 1-2-2V19a17 17 0 0 0-34 0v13a2 2 0 0 1-2 2 4 4 0 0 0-4 4v1a1 1 0 0 0 1 1h44a1 1 0 0 0 1-1v-1a4 4 0 0 0-4-4Z" />
    </Svg>
)

export default AlertIcon
