import React, { useContext, useEffect, useState } from "react";
import {
  Linking,
  Pressable,
  ScrollView,
  useWindowDimensions,
  View,
} from "react-native";
import DropDownPicker from "react-native-dropdown-picker";
import { CustomText } from "./common/custom-text-component";
import ApiService from "../service/api-service";
import { isEmpty, isNil, isString, isArray } from "lodash";
import { AppStateContext } from "../util/context/app-context";
import { addRouteToChain, beautifyKey } from "../util/method";
import { Overlay } from "./common/overlay-component";
import { CalendarScheduler } from "./common/calendar-scheduler-component";
import { TimelineDetailInfo } from "./common/timeline-detail-info-component";
import RingChart from "./RingChart";
import Constant from "../util/constants";
import GlobalCSS from "../util/style";
import { Feather, MaterialIcons } from "@expo/vector-icons";
import CreateResponsiveStyle, {
  Large,
  Medium,
} from "../util/media-query-wrapper";
import moment from "moment";
import { Auth } from "aws-amplify";
import RingChartWithoutPercentage from "./RingChart/RingChartWithoutPercentage";

const { STYLES } = Constant;
const { LIGHT_GREY } = STYLES;
const PROFILE_TAB = "PROFILE_TAB";
const ENGAGEMENT_DETAILS_TAB = "ENGAGEMENT_DETAILS_TAB";

export const CustomerProfilePage = (props) => {
  const { navigation } = props;
  const { data } = props.route.params || props;
  const route = { name: "PROFILE_DETAILS", title: `${data.name}` };
  const [source, setSource] = useState({});
  const [agentEmail, setAgentEmail] = useState("");
  const [upComingApts, setUpComingApts] = useState([]);
  const [activeTab, setActiveTab] = useState(PROFILE_TAB);
  const [toggleScheduler, setToggleScheduler] = useState(false);
  const [hasRefresh, setHasRefresh] = useState(true);
  const context = useContext(AppStateContext);
  const [filterOpen, setFilterOpen] = useState(false);
  const [filterValue, setFilterValue] = useState("total");
  const [filterItems, setFilterItems] = useState([
    { label: "Total", value: "total" },
    { label: "Today", value: "today" },
    { label: "This Month", value: "this_month" },
    { label: "Last Month", value: "last_month" },
  ]);
  const [drugCategory, setDrugCategory] = useState([]);
  const [cltv, setCltv] = useState([]);
  const [callStat, setCallStat] = useState([]);
  const [sampleStat, setSampleStat] = useState([]);
  const [eventsStat, setEventsStat] = useState([]);
  const layout = useWindowDimensions();
  const styles = responsiveStyle(layout);

  useEffect(() => {
    return navigation.addListener("focus", () => {
      context.setHeader(`${data.name}`);
      const newChain = addRouteToChain(context.routeChain, route);
      context.setRouteChain(newChain);
    });
  }, [navigation]);

  useEffect(() => {
    getProfile();
  }, [hasRefresh]);

  useEffect(() => {
    async function getUserInfo() {
      try {
        const result = await Auth.currentUserInfo();
        setAgentEmail(result.attributes.email);
      } catch (error) {
        context.setOverlayState(true, error.toString());
      }
    }

    getUserInfo();
  }, []);

  async function getProfile() {
    try {
      context.setOverlayState(true);
      console.log("UI - API called to get Profile");
      const result = await ApiService.getProfile(data.email);
      setSource(result);
      setDrugCategory(result.DRUG_CATEGORY);
      setCltv(result.CLTV);
      setUpComingApts(result.UP_COMING_APT);
      setCallStat(result.CALLS_STAT);
      setEventsStat(result.EVENTS_STAT);
      setSampleStat(result.SAMPLES_VS_INVENTORY_STAT);
      context.setOverlayState(false);
    } catch (error) {
      context.setOverlayState(true, error.toString());
    }
  }

  async function zoomLinkClicked(apt) {
    const { zoomLink = "" } = apt;
    const path = new URL(zoomLink).host;
    await Linking.openURL(zoomLink);
    if (path.includes("treasure-data.zoom.us")) {
      const paramExcludedLink = zoomLink.split("?")[0];
      const meetingId = paramExcludedLink.substring(
        paramExcludedLink.length - 11,
        paramExcludedLink.length
      );
      if (meetingId.length !== 11) {
        throw new Error("Zoom meeting ID unmatched");
      }
      await ApiService.createCalendlyZoomMeeting(
        {
          join_url: zoomLink,
          meeting_id: meetingId,
        },
        {
          email: source.email[0],
          name: data.name,
          td_client_id: source.latest_td_client_id,
        },
        agentEmail
      );
    }
    setTimeout(function () {
      setHasRefresh(!hasRefresh);
    }, 13000);
  }

  const Card = (props) => {
    const { customStyle, children, header } = props;
    return (
      <View
        style={[styles("chartCardContainer"), GlobalCSS["large"], customStyle]}
      >
        <View
          style={{
            flex: 1,
            borderColor: "#E6E6E6",
            borderBottomWidth: 2,
            justifyContent: "center",
            alignContent: "space-between",
            alignItems: "center",
          }}
        >
          {!isNil(header) && (
            <CustomText
              style={[styles("font"), { fontWeight: "bold", marginBottom: 15 }]}
            >
              {header}
            </CustomText>
          )}
          <View style={[styles("chartCard")]}>{children}</View>
        </View>
      </View>
    );
  };

  function renderGender(gender) {
    switch (gender) {
      case "M":
        return "Male";
      case "F":
        return "Female";
      default:
        return "Unknown";
    }
  }

  return (
    <View style={{ flex: 1, backgroundColor: LIGHT_GREY }}>
      {toggleScheduler && (
        <Overlay backgroundColor={LIGHT_GREY}>
          <CalendarScheduler
            client={{
              email: source.email[0],
              name: data.name,
              td_client_id: source.latest_td_client_id,
            }}
            setToggleScheduler={setToggleScheduler}
            refresh={() => setHasRefresh(!hasRefresh)}
          />
        </Overlay>
      )}
      <View style={{ flex: 1, rowGap: "1rem" }}>
        <View style={styles("tabs")}>
          <Pressable
            style={[styles("tab"), { marginRight: 50 }]}
            onPress={() => setActiveTab(PROFILE_TAB)}
          >
            {activeTab === PROFILE_TAB ? (
              <View
                style={{
                  backgroundColor: "rgb(255, 246, 243)",
                  borderWidth: 1,
                  borderColor: "rgb(249, 198, 181)",
                  borderRadius: 30,
                  padding: 10,
                }}
              >
                <CustomText
                  style={[
                    { fontWeight: "bold", color: "rgb(206, 107, 74)" },
                    styles("font"),
                  ]}
                >
                  Customer Profile
                </CustomText>
              </View>
            ) : (
              <CustomText style={[{ fontWeight: "bold" }, styles("font")]}>
                Customer Profile
              </CustomText>
            )}
          </Pressable>
          <Pressable
            style={styles("tab")}
            onPress={() => setActiveTab(ENGAGEMENT_DETAILS_TAB)}
          >
            {activeTab === ENGAGEMENT_DETAILS_TAB ? (
              <View
                style={{
                  backgroundColor: "rgb(255, 246, 243)",
                  borderWidth: 1,
                  borderColor: "rgb(249, 198, 181)",
                  borderRadius: 30,
                  padding: 10,
                }}
              >
                <CustomText
                  style={[
                    { fontWeight: "bold", color: "rgb(206, 107, 74)" },
                    styles("font"),
                  ]}
                >
                  Engagement Details
                </CustomText>
              </View>
            ) : (
              <CustomText style={[{ fontWeight: "bold" }, styles("font")]}>
                Engagement Details
              </CustomText>
            )}
          </Pressable>
          <View
            style={{
              flex: 1,
              alignItems: "flex-end",
            }}
          >
            <Pressable
              style={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgb(255, 246, 243)",
                borderWidth: 1,
                borderColor: "rgb(249, 198, 181)",
                borderRadius: 30,
                padding: 10,
              }}
              onPress={() => setHasRefresh(!hasRefresh)}
            >
              <MaterialIcons
                name="refresh"
                size={20}
                color="rgb(206, 107, 74)"
                style={{ marginRight: 5 }}
              />
              <CustomText
                style={[
                  { paddingHorizontal: 5, color: "rgb(206, 107, 74)" },
                  styles("font"),
                ]}
              >
                Refresh
              </CustomText>
            </Pressable>
          </View>
        </View>
        <View style={styles("canvas")}>
          {activeTab === PROFILE_TAB && (
            // customer info
            <View style={{ flex: 1, flexDirection: "row" }}>
              <View
                style={{
                  flex: 3,
                  borderRightWidth: 2,
                  borderColor: "#E6E6E6",
                  paddingRight: 15,
                  marginRight: 15,
                }}
              >
                <View style={{ flex: 1 }}>
                  <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
                    <View
                      style={{
                        width: "100%",
                        borderBottomColor: "#E6E6E6",
                        borderStyle: "solid",
                        borderBottomWidth: 2,
                      }}
                    >
                      <View style={{ minHeight: 50 }}>
                        <CustomText
                          style={[styles("headerFont"), { fontWeight: "bold" }]}
                        >
                          {data.name || ""}
                        </CustomText>
                        {source.email &&
                          source.email.map((email, index) => (
                            <CustomText key={index} style={styles("smallFont")}>
                              {email}
                            </CustomText>
                          ))}
                      </View>
                      <View
                        style={{
                          marginVertical: 7,
                          flexDirection: "row",
                        }}
                      >
                        <View style={{ width: "50%" }}>
                          <CustomText
                            style={[{ fontWeight: "bold" }, styles("font")]}
                          >
                            Phone:
                          </CustomText>
                        </View>
                        <View>
                          {source.phone &&
                            source.phone.map((phoneNum, index) => (
                              <CustomText key={index} style={[styles("font")]}>
                                {phoneNum}
                              </CustomText>
                            ))}
                        </View>
                      </View>
                      <View
                        style={{
                          marginVertical: 7,
                          flexDirection: "row",
                        }}
                      >
                        <View style={{ width: "50%" }}>
                          <CustomText
                            style={[{ fontWeight: "bold" }, styles("font")]}
                          >
                            Address:
                          </CustomText>
                        </View>
                        <View style={{ flex: 1 }}>
                          {source.address &&
                            source.address.map((addr, index) => (
                              <CustomText key={index} style={[styles("font")]}>
                                {addr}
                              </CustomText>
                            ))}
                        </View>
                      </View>
                      <View
                        style={{
                          marginVertical: 7,
                          flexDirection: "row",
                        }}
                      >
                        <View style={{ width: "50%" }}>
                          <CustomText
                            style={[{ fontWeight: "bold" }, styles("font")]}
                          >
                            Gender:
                          </CustomText>
                        </View>
                        <CustomText style={[styles("font")]}>
                          {renderGender(source.gender)}
                        </CustomText>
                      </View>
                    </View>
                    <View
                      style={{
                        width: "100%",
                        borderBottomColor: "#E6E6E6",
                        borderStyle: "solid",
                        borderBottomWidth: 2,
                      }}
                    >
                      <View
                        style={{
                          minHeight: 50,
                          marginTop: 10,
                        }}
                      >
                        <CustomText
                          style={[
                            styles("headerFont"),
                            {
                              fontWeight: "bold",
                            },
                          ]}
                        >
                          Department
                        </CustomText>
                      </View>
                      <View
                        style={{
                          marginVertical: 7,
                          flexDirection: "row",
                        }}
                      >
                        <View style={{ width: "50%" }}>
                          <CustomText
                            style={[{ fontWeight: "bold" }, styles("font")]}
                          >
                            Hospital Name:
                          </CustomText>
                        </View>
                        <CustomText style={[styles("font")]}>
                          {source.hospital_name || ""}
                        </CustomText>
                      </View>
                      <View
                        style={{
                          marginVertical: 7,
                          flexDirection: "row",
                        }}
                      >
                        <View style={{ width: "50%" }}>
                          <CustomText
                            style={[{ fontWeight: "bold" }, styles("font")]}
                          >
                            Primary Speciality:
                          </CustomText>
                        </View>
                        <CustomText style={[styles("font")]}>
                          {source.prim_name || ""}
                        </CustomText>
                      </View>
                      <View
                        style={{
                          marginVertical: 7,
                          flexDirection: "row",
                        }}
                      >
                        <View style={{ width: "50%" }}>
                          <CustomText
                            style={[{ fontWeight: "bold" }, styles("font")]}
                          >
                            Secondary Speciality:
                          </CustomText>
                        </View>
                        <CustomText style={[styles("font")]}>
                          {source.secry_name || ""}
                        </CustomText>
                      </View>
                    </View>
                    <View style={{ rowGap: 15, marginVertical: 15 }}>
                      <View style={{ width: "100%" }}>
                        <View style={styles("interactionPanel")}>
                          <View
                            style={{
                              width: "100%",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <CustomText
                              style={[
                                styles("headerFont"),
                                { fontWeight: "bold" },
                              ]}
                            >
                              Upcoming Appointments
                            </CustomText>
                            <Pressable
                              style={{
                                backgroundColor: "rgb(255, 246, 243)",
                                borderWidth: 1,
                                borderColor: "rgb(249, 198, 181)",
                                borderRadius: 30,
                                padding: 10,
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "row",
                              }}
                              onPress={() =>
                                setToggleScheduler(!toggleScheduler)
                              }
                            >
                              <Feather
                                name="plus"
                                size={17}
                                color="rgb(206, 107, 74)"
                              />
                              <CustomText
                                style={[
                                  styles("font"),
                                  { color: "rgb(206, 107, 74)" },
                                ]}
                              >
                                New
                              </CustomText>
                            </Pressable>
                          </View>
                          <View style={{ flex: 1, width: "100%" }}>
                            <ScrollView>
                              {upComingApts.map((apt, index) => {
                                const { time = "unknown", date = "unknown" } =
                                  apt;
                                let { isDisabled = true } = apt;
                                return (
                                  <Pressable
                                    key={index}
                                    style={{
                                      width: "100%",
                                      marginVertical: 10,
                                      justifyContent: "center",
                                      alignItems: "center",
                                      flexDirection: "row",
                                    }}
                                    disabled={isDisabled}
                                    onPress={() => {
                                      zoomLinkClicked(apt);
                                    }}
                                  >
                                    <View
                                      style={[
                                        {
                                          width: "2%",
                                          backgroundColor: "#9d9d9d",
                                          marginRight: 15,
                                          height: "100%",
                                          borderRadius: 3,
                                        },
                                        !apt.isDisabled
                                          ? styles("activeZoom")
                                          : "",
                                      ]}
                                    />
                                    <View
                                      style={{ flex: 1, paddingVertical: 3 }}
                                    >
                                      <CustomText
                                        style={
                                          !apt.isDisabled
                                            ? styles("activeZoomText")
                                            : { color: "#9d9d9d" }
                                        }
                                      >
                                        {date}
                                      </CustomText>
                                      <CustomText
                                        style={
                                          !apt.isDisabled
                                            ? styles("activeZoomText")
                                            : { color: "#9d9d9d" }
                                        }
                                      >
                                        {time}
                                      </CustomText>
                                    </View>
                                  </Pressable>
                                );
                              })}
                            </ScrollView>
                          </View>
                        </View>
                      </View>
                    </View>
                  </ScrollView>
                </View>
              </View>
              {/*customer chart*/}
              <View
                style={{
                  flex: 10,
                  backgroundColor: "white",
                  borderRadius: 30,
                  padding: 15,
                }}
              >
                <ScrollView showsVerticalScrollIndicator={true}>
                  <View
                    style={{
                      width: "100%",
                      paddingBottom: "1rem",
                      flexDirection: "row",
                      borderBottomColor: "#E6E6E6",
                      borderStyle: "solid",
                      borderBottomWidth: 2,
                      zIndex: 1,
                      alignItems: "center",
                      rowGap: "1rem",
                    }}
                  >
                    <View style={{ flex: 1 }}>
                      <CustomText
                        style={[styles("headerFont"), { fontWeight: "bold" }]}
                      >
                        Provider ID: {source.latest_td_client_id || ""}
                      </CustomText>
                    </View>
                    <View>
                      <DropDownPicker
                        open={filterOpen}
                        showTickIcon={false}
                        value={filterValue}
                        items={filterItems}
                        setOpen={setFilterOpen}
                        setValue={setFilterValue}
                        setItems={setFilterItems}
                        style={styles("dropdown")}
                        itemSeparator={true}
                        itemSeparatorStyle={{ backgroundColor: "#E6E6E6" }}
                        listItemLabelStyle={{ fontSize: 15 }}
                        dropDownContainerStyle={{
                          width: "100%",
                          alignItems: "flex-start",
                          borderWidth: 1,
                          borderColor: "#E6E6E6",
                          rowGap: "1rem",
                        }}
                        listItemContainerStyle={{
                          padding: "0.5rem",
                          width: "100%",
                        }}
                      />
                    </View>
                  </View>
                  {!isEmpty(source.segments_info) && (
                    <View
                      style={{
                        width: "100%",
                        paddingVertical: "1rem",
                        flexDirection: "row",
                        borderBottomColor: "#E6E6E6",
                        borderStyle: "solid",
                        borderBottomWidth: 2,
                        zIndex: -1,
                        alignItems: "center",
                        columnGap: "2rem",
                      }}
                    >
                      <CustomText
                        style={[styles("headerFont"), { fontWeight: "bold" }]}
                      >
                        Segments:
                      </CustomText>
                      {source.segments_info.map((segment, index) => (
                        <View
                          key={index}
                          style={{
                            backgroundColor:
                              index === 0 ? "#e6e3fb" : "rgb(235, 235, 235)",
                            borderWidth: 1,
                            borderColor: index === 0 ? "#ccc5f2" : "#9e8fcb",
                            borderRadius: 30,
                            padding: 10,
                            minWidth: 80,
                            alignItems: "center",
                          }}
                        >
                          <CustomText
                            style={[
                              {
                                fontWeight: "bold",
                                color: index === 0 ? "#9385e3" : "#9e8fcb",
                              },
                              styles("font"),
                            ]}
                          >
                            {segment.segment_name}
                          </CustomText>
                        </View>
                      ))}
                    </View>
                  )}
                  {/* Charts  container */}
                  <View
                    style={{
                      flexFlow: "row wrap",
                      justifyContent: "space-between",
                    }}
                  >
                    {/* call pie chart */}
                    <Card
                      customStyle={{
                        justifyContent: "space-between",
                      }}
                    >
                      <RingChart
                        data={callStat[filterValue]}
                        title="Calls taken vs Scheduled"
                      />
                    </Card>
                    <Large>
                      <View
                        style={{
                          borderColor: "#E6E6E6",
                          borderRightWidth: 2,
                          marginVertical: 10,
                        }}
                      />
                    </Large>
                    <Medium>
                      <View
                        style={{
                          borderColor: "#E6E6E6",
                          borderRightWidth: 2,
                          marginVertical: 10,
                        }}
                      />
                    </Medium>
                    {/* sample inventory chart */}
                    <Card
                      customStyle={{
                        justifyContent: "space-between",
                      }}
                    >
                      <RingChart
                        data={sampleStat[filterValue]}
                        title="Sample Sold vs Inventory"
                      />
                    </Card>
                    <Large>
                      <View
                        style={{
                          borderColor: "#E6E6E6",
                          borderRightWidth: 2,
                          marginVertical: 10,
                        }}
                      />
                    </Large>
                    {/* CLTV chart */}
                    <Card
                      customStyle={{
                        justifyContent: "space-between",
                      }}
                    >
                      <RingChartWithoutPercentage
                        data={cltv[filterValue]}
                        title="Customer Life Time Value (CLTV)"
                      />
                    </Card>
                    <Medium>
                      <View
                        style={{
                          borderColor: "#E6E6E6",
                          borderRightWidth: 2,
                          marginVertical: 10,
                        }}
                      />
                    </Medium>
                    {/* event chart */}
                    <Card
                      customStyle={{
                        justifyContent: "space-between",
                      }}
                    >
                      <RingChart
                        data={eventsStat[filterValue]}
                        title="No. of events subscribes"
                      />
                    </Card>
                    <Large>
                      <View
                        style={{
                          borderColor: "#E6E6E6",
                          borderRightWidth: 2,
                          marginVertical: 10,
                        }}
                      />
                    </Large>
                    {/* drug category chart */}
                    <Card
                      customStyle={{
                        justifyContent: "space-between",
                      }}
                    >
                      <RingChart
                        data={drugCategory[filterValue]}
                        title="Drug purchase category"
                      />
                    </Card>
                  </View>
                  <View
                    style={{
                      flexFlow: "row",
                    }}
                  >
                    {/*/!* Next Best Product *!/*/}
                    <View
                      style={[
                        {
                          minHeight: 150,
                          paddingVertical: 5,
                          rowGap: 5,
                          alignItems: "center",
                        },
                        styles("NB_card"),
                      ]}
                    >
                      <CustomText
                        style={[styles("font"), { fontWeight: "bold" }]}
                      >
                        Next Best Products
                      </CustomText>
                      <View
                        style={{
                          flex: 1,
                          width: "100%",
                          justifyContent: "center",
                          alignItems: "flex-start",
                          paddingBottom: 5,
                        }}
                      >
                        {!isEmpty(source.nbp) &&
                          Object.values(source.nbp).map((product, index) => (
                            <View key={index}>
                              {!isEmpty(product) && (
                                <View
                                  style={{
                                    flexDirection: "row",
                                    alignItems: "center",
                                  }}
                                >
                                  <CustomText style={{ fontSize: 6 }}>
                                    {"\u2B24"}{" "}
                                  </CustomText>
                                  <CustomText style={styles("font")}>
                                    {product}
                                  </CustomText>
                                </View>
                              )}
                            </View>
                          ))}
                      </View>
                      <View
                        style={{
                          width: "100%",
                          position: "absolute",
                          bottom: 0,
                          paddingHorizontal: 10,
                        }}
                      >
                        <View
                          style={{
                            width: "100%",
                            borderColor: "#E6E6E6",
                            borderBottomWidth: 2,
                          }}
                        />
                      </View>
                    </View>
                    <View
                      style={{
                        height: "100%",
                        paddingVertical: 10,
                        width: 2,
                      }}
                    >
                      <View
                        style={{
                          height: "100%",
                          backgroundColor: "#E6E6E6",
                        }}
                      />
                    </View>
                    {/*/!*Next Best Action*!/*/}
                    <View
                      style={[
                        {
                          minHeight: 150,
                          paddingVertical: 5,
                          rowGap: 5,
                          alignItems: "center",
                        },
                        styles("NB_card"),
                      ]}
                    >
                      <CustomText
                        style={[styles("font"), { fontWeight: "bold" }]}
                      >
                        Next Best Actions
                      </CustomText>
                      <View
                        style={{
                          flex: 1,
                          width: "100%",
                          justifyContent: "center",
                          alignItems: "flex-start",
                          paddingBottom: 5,
                        }}
                      >
                        {!isEmpty(source.next_best_action) &&
                          Object.keys(source.next_best_action).map(
                            (key, index) => {
                              let temp = key;
                              if (key === "best_mode_to_connect") {
                                temp = "best_method_to_connect";
                              }
                              return (
                                <View key={index}>
                                  {!isEmpty(source.next_best_action[key]) && (
                                    <View style={{ flexDirection: "row" }}>
                                      <CustomText
                                        style={[
                                          styles("font"),
                                          { marginRight: 10 },
                                        ]}
                                      >
                                        {beautifyKey(temp)}:
                                      </CustomText>
                                      {isString(
                                        source.next_best_action[key]
                                      ) && (
                                        <CustomText style={styles("font")}>
                                          {source.next_best_action[key]}
                                        </CustomText>
                                      )}
                                      {isArray(
                                        source.next_best_action[key]
                                      ) && (
                                        <CustomText style={styles("font")}>
                                          {source.next_best_action[key].join(
                                            ", "
                                          )}
                                        </CustomText>
                                      )}
                                    </View>
                                  )}
                                </View>
                              );
                            }
                          )}
                      </View>
                      <View
                        style={{
                          width: "100%",
                          position: "absolute",
                          bottom: 0,
                          paddingHorizontal: 10,
                        }}
                      >
                        <View
                          style={{
                            width: "100%",
                            borderColor: "#E6E6E6",
                            borderBottomWidth: 2,
                          }}
                        />
                      </View>
                    </View>
                  </View>
                  <View
                    style={{
                      flexFlow: "row",
                    }}
                  >
                    {/*/!*Next Best Suggestion*!/*/}
                    <View
                      style={[
                        {
                          minHeight: 150,
                          paddingVertical: 5,
                          rowGap: 5,
                          alignItems: "center",
                        },
                        styles("NB_card"),
                      ]}
                    >
                      <CustomText
                        style={[styles("font"), { fontWeight: "bold" }]}
                      >
                        Next Best Suggestions
                      </CustomText>
                      <View
                        style={{
                          flex: 1,
                          width: "100%",
                          justifyContent: "center",
                          alignItems: "flex-start",
                          paddingBottom: 5,
                        }}
                      >
                        {!isEmpty(source.next_best_suggestion) &&
                          source.NEXT_BEST_SUGGESTIONS.map((item, index) => {
                            const { timestamp, suggestion } = item;
                            return (
                              <View
                                key={index}
                                style={{
                                  flexDirection: "row",
                                  alignItems: "flex-start",
                                  marginVertical: 7,
                                }}
                              >
                                <CustomText
                                  style={{
                                    fontSize: 6,
                                    paddingTop: 6,
                                    marginRight: 3,
                                  }}
                                >
                                  {"\u2B24"}{" "}
                                </CustomText>
                                <CustomText style={styles("font")}>
                                  {suggestion}
                                </CustomText>
                              </View>
                            );
                          })}
                      </View>
                      <View
                        style={{
                          width: "100%",
                          position: "absolute",
                          bottom: 0,
                          paddingHorizontal: 10,
                        }}
                      >
                        <View
                          style={{
                            width: "100%",
                            borderColor: "#E6E6E6",
                            borderBottomWidth: 2,
                          }}
                        />
                      </View>
                    </View>
                    <View
                      style={{
                        height: "100%",
                        paddingVertical: 10,
                        width: 2,
                      }}
                    >
                      <View
                        style={{
                          height: "100%",
                          backgroundColor: "#E6E6E6",
                        }}
                      />
                    </View>
                    <View
                      style={[
                        {
                          minHeight: 150,
                          paddingVertical: 5,
                          rowGap: 5,
                          alignItems: "center",
                        },
                        styles("NB_card"),
                      ]}
                    >
                      <CustomText
                        style={[styles("font"), { fontWeight: "bold" }]}
                      >
                        Consent History
                      </CustomText>
                      <View
                        style={{
                          flex: 1,
                          width: "100%",
                          justifyContent: "center",
                          alignItems: "flex-start",
                          paddingBottom: 5,
                        }}
                      >
                        {!isEmpty(source.CONSENT_HISTORY) &&
                          source.CONSENT_HISTORY.map((consent, index) => {
                            const consentTime = moment
                              .utc(consent.timestamp)
                              .local()
                              .format("MMM DD YYYY h:mm");
                            return (
                              <CustomText key={index} style={styles("font")}>
                                {consentTime} - {beautifyKey(consent.status)}
                              </CustomText>
                            );
                          })}
                      </View>
                      <View
                        style={{
                          width: "100%",
                          position: "absolute",
                          bottom: 0,
                          paddingHorizontal: 10,
                        }}
                      >
                        <View
                          style={{
                            width: "100%",
                            borderColor: "#E6E6E6",
                            borderBottomWidth: 2,
                          }}
                        />
                      </View>
                    </View>
                  </View>
                </ScrollView>
              </View>
            </View>
          )}
          {activeTab === ENGAGEMENT_DETAILS_TAB && (
            // engagement detail
            <View style={{ flex: 1, marginTop: 15 }}>
              <TimelineDetailInfo data={source} />
            </View>
          )}
        </View>
      </View>
    </View>
  );
};

const responsiveStyle = CreateResponsiveStyle(
  {
    tabs: {
      height: 50,
      flexDirection: "row",
      borderBottomColor: "black",
      borderStyle: "solid",
      borderBottomWidth: 1,
    },
    canvas: {
      flex: 1,
    },
    tab: {
      height: "100%",
      justifyContent: "center",
    },
    activeTab: {
      borderStyle: "solid",
      borderBottomWidth: 3,
      borderBottomColor: "black",
    },
    scorePanel: {
      height: "60%",
      alignItems: "center",
      justifyContent: "space-evenly",
      elevation: 1,
      shadowColor: "grey",
      shadowOpacity: 0.26,
      shadowOffset: { width: 1, height: 1 },
      shadowRadius: 10,
      backgroundColor: "white",
    },
    interactionPanel: {
      flex: 1,
      alignItems: "center",
      color: "inherit",
    },
    chartCard: {
      flex: 1,
      width: "100%",
      justifyContent: "center",
      alignContent: "space-between",
      alignItems: "center",
      padding: 5,
    },
    chartCardContainer: {
      flex: 1,
      width: "100%",
      paddingHorizontal: 10,
      minWidth: "33%",
    },
    dropdown: {
      width: 115,
      flexDirection: "row",
      textAlign: "center",
      borderRadius: 10,
      lineHeight: 3,
      textAlignVertical: "center",
      padding: 10,
      fontWeight: "bold",
    },
    headerFont: {
      fontSize: 22,
    },
    font: {
      fontSize: 17,
    },
    smallFont: {
      fontSize: 15,
    },
    activeZoom: {
      borderRightWidth: 5,
      borderColor: "#C5795E",
      backgroundColor: "#C5795E",
    },
    activeZoomText: {
      color: "black",
    },
    NB_card: {
      paddingHorizontal: 10,
      width: "50%",
    },
  },
  {},
  {
    headerFont: {
      fontSize: 20,
    },
    font: {
      fontSize: 15,
    },
    chartCardContainer: {
      minWidth: "49%",
    },
    smallFont: {
      fontSize: 13,
    },
  },
  {
    chartCardContainer: {
      minWidth: "100%",
    },
  }
);
