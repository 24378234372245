import React, { useEffect, useState } from "react";
import { StyleSheet } from "react-native";
import DropDownPicker from "react-native-dropdown-picker";

export const DropdownSingleComponent = (props) => {
  const {
    dropdownList = [],
    style = {},
    showTick = false,
    setSelected,
  } = props;
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState([]);
  const [items, setItems] = useState(dropdownList);

  useEffect(() => {
    setDefaultValue();
    setItems(dropdownList);
  }, [dropdownList]);

  function setDefaultValue() {
    dropdownList.forEach((item) => {
      if (item.isSelected) {
        setValue(item.value);
      }
    });
  }

  function selectionCallBack(value) {
    setSelected(value);
  }

  return (
    <DropDownPicker
      open={open}
      showTickIcon={showTick}
      value={value}
      items={items}
      setOpen={setOpen}
      setValue={setValue}
      setItems={setItems}
      style={[styles.dropdown, { ...style }]}
      closeAfterSelecting={true}
      listItemLabelStyle={{ fontSize: 14 }}
      dropDownContainerStyle={{
        width: "100%",
        alignItems: "center",
        borderWidth: 2,
        paddingRight: 20,
        borderColor: "#9d9d9d",
        rowGap: "1rem",
        zIndex: 1,
        maxHeight: null,
        paddingVertical: "1rem",
      }}
      onChangeValue={(value) => {
        selectionCallBack(value);
      }}
      listItemContainerStyle={{
        flexDirection: "row",
        width: "100%",
        marginVertical: 2,
      }}
      selectedItemLabelStyle={{
        fontWeight: "bold",
      }}
    />
  );
};

const styles = StyleSheet.create({
  dropdown: {
    backgroundColor: "white",
    width: "100%",
    flexDirection: "row",
    textAlign: "center",
    borderRadius: 10,
    lineHeight: 3,
    textAlignVertical: "center",
    padding: 10,
    marginRight: 15,
    zIndex: 1,
    borderColor: "#9d9d9d",
    borderWidth: 2,
  },
});
