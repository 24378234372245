import * as React from "react"
import Svg, { Path } from "react-native-svg"

const AccountIcon = (props) => (
    <Svg
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 62.96 68"
        {...props}
    >
        <Path d="M31.51 40a20 20 0 1 0-20-20 20.06 20.06 0 0 0 20 20Zm0-36.4a16.3 16.3 0 1 1-16.3 16.3 16.3 16.3 0 0 1 16.3-16.3Z" />
        <Path d="M60.81 41.3c-1.5-1.5-3.6-2.3-6.6-2.3h-5.1a2.45 2.45 0 0 0-1.2.4 25.41 25.41 0 0 1-32.8 0 1.75 1.75 0 0 0-1.2-.4h-5.1c-3 0-5.1.7-6.6 2.3-2.5 2.6-2.3 6.7-2.1 11.4.1 1.3.1 2.7.1 4.1v9.4a1.79 1.79 0 0 0 1.8 1.8h58.9a1.79 1.79 0 0 0 1.8-1.8v-9.4c0-1.4.1-2.8.1-4.1.3-4.7.4-8.8-2-11.4Zm-1.6 11.3c-.1 1.4-.1 2.8-.1 4.3v7.5H3.91v-7.5c0-1.5-.1-2.9-.1-4.3-.2-3.9-.3-7.3 1.1-8.7.7-.8 2-1.1 3.9-1.1h4.4a29 29 0 0 0 36.6 0h4.4c1.9 0 3.2.4 3.9 1.1 1.4 1.4 1.3 4.8 1.1 8.7Z" />
    </Svg>
)

export default AccountIcon
