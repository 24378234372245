import React, { useContext, useState } from "react";
import { Pressable, StyleSheet, TextInput, View } from "react-native";
import { CustomText } from "../common/custom-text-component";
import { Auth } from "aws-amplify";
import { AppStateContext } from "../../util/context/app-context";
import Constants from "../../util/constants";
import GlobalCSS from "../../util/style";

export const ResetPasswordComponent = (props) => {
  const { onPressSignInSubmission, firstTime, userInfo } = props;
  const [email, setEmail] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [code, setCode] = useState("");
  const context = useContext(AppStateContext);

  async function resetPassword() {
    try {
      context.setOverlayState(true);
      if (firstTime) {
        Auth.signIn(userInfo.email, userInfo.password).then((user) => {
          if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
            Auth.completeNewPassword(user, newPassword).then((user) => {});
          }
        });
      } else {
        await Auth.forgotPasswordSubmit(email, code, newPassword);
        onPressSignInSubmission();
      }
      context.setOverlayState(false);
    } catch (error) {
      context.setOverlayState(true, error.toString());
    }
  }

  async function getConfirmationCode() {
    try {
      context.setOverlayState(true);
      await Auth.forgotPassword(email);
      context.setOverlayState(false);
    } catch (error) {
      context.setOverlayState(true, error.toString());
    }
  }

  return (
    <View style={{ flex: 1 }}>
      <View style={[styles.body]}>
        <View
          style={{
            width: "70%",
            height: "100%",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <TextInput
            style={[styles.item, { paddingLeft: 10 }]}
            placeholder={"Email"}
            onChangeText={setEmail}
            editable={!firstTime}
            defaultValue={userInfo.email || ""}
          />
          {!firstTime && (
            <View style={{ flexDirection: "row", width: "100%" }}>
              <TextInput
                style={[
                  styles.item,
                  {
                    paddingLeft: 10,
                    marginRight: 20,
                  },
                ]}
                placeholder={"Enter your code"}
                onChangeText={setCode}
              />
              <Pressable
                style={[styles.button, GlobalCSS.center, { width: "30%" }]}
                onPress={async () => {
                  await getConfirmationCode();
                }}
              >
                <CustomText style={[styles.font, { color: "white" }]}>
                  Get Code
                </CustomText>
              </Pressable>
            </View>
          )}
          {firstTime && (
            <TextInput
              style={[styles.item, { paddingLeft: 10 }]}
              placeholder={"Old Password"}
              onChangeText={setOldPassword}
              editable={!firstTime}
              defaultValue={userInfo.password || ""}
              secureTextEntry={true}
            />
          )}
          <TextInput
            style={[styles.item, { paddingLeft: 10 }]}
            placeholder={"New Password"}
            onChangeText={setNewPassword}
            secureTextEntry={true}
          />
          <View style={{ width: "100%", flexDirection: "row" }}>
            <Pressable
              style={[
                {
                  flex: 1,
                  justifyContent: "center",
                  alignItems: "flex-start",
                },
              ]}
              onPress={onPressSignInSubmission}
            >
              <CustomText
                style={[
                  styles.font,
                  {
                    color: Constants.STYLES.BLUE,
                    fontSize: 13,
                    textDecorationLine: "underline",
                  },
                ]}
              >
                Back to Sign In
              </CustomText>
            </Pressable>
            <Pressable
              style={[styles.button, GlobalCSS.center, { width: "50%" }]}
              onPress={async () => {
                await resetPassword();
              }}
            >
              <CustomText style={[styles.font, { color: "white" }]}>
                Reset Password
              </CustomText>
            </Pressable>
          </View>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  body: {
    paddingHorizontal: 30,
    justifyContent: "space-between",
    width: "100%",
    height: "90%",
    alignItems: "center",
  },
  item: {
    borderWidth: 1,
    borderColor: "grey",
    borderRadius: 9,
    minHeight: 55,
    width: "100%",
  },
  font: {
    fontSize: 16,
  },
  button: {
    backgroundColor: "#5061F7",
    color: "white",
    minHeight: 50,
    borderRadius: 10,
  },
});
