import { StyleSheet } from "react-native";

export default StyleSheet.create({
  h1: {
    fontSize: 40,
    fontWeight: "bold",
    fontFamily: "Nunito-Regular",
  },
  h2: {
    fontSize: 20,
    fontWeight: "bold",
    fontFamily: "Nunito-Regular",
  },
  mini: {
    width: "10%",
  },
  small: {
    width: "25%",
  },
  medium: {
    width: "33%",
  },
  large: {
    width: "50%",
  },
  center: {
    justifyContent: "center",
    alignItems: "center",
  },
});
