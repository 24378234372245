import * as React from "react"
import Svg, { Path } from "react-native-svg"

const SupportIcon = (props) => (
    <Svg
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 90.1 87.2"
        {...props}
    >
        <Path d="M66.1 30.3a5.48 5.48 0 0 0-5.5 5.5 5.55 5.55 0 0 0 5.5 5.5 5.5 5.5 0 0 0 0-11ZM45.9 30.3a5.48 5.48 0 0 0-5.5 5.5 5.5 5.5 0 1 0 5.5-5.5ZM25.6 30.3a5.48 5.48 0 0 0-5.5 5.5 5.55 5.55 0 0 0 5.5 5.5 5.5 5.5 0 1 0 0-11Z" />
        <Path d="M75.3 0H14.6A14.7 14.7 0 0 0 0 14.7v39.6a14.62 14.62 0 0 0 14.6 14.6h28.5L74 87.2l-1.5-18.3h2.9a14.7 14.7 0 0 0 14.7-14.6V14.7A14.87 14.87 0 0 0 75.3 0Zm10.5 54.2a10.39 10.39 0 0 1-10.4 10.4h-7.5l1.2 14.7-24.8-14.6H14.6A10.39 10.39 0 0 1 4.2 54.3V14.7A10.39 10.39 0 0 1 14.6 4.3h60.7a10.39 10.39 0 0 1 10.4 10.4v39.5Z" />
    </Svg>
)

export default SupportIcon
