import React, { useContext, useEffect, useState } from "react";
import { Pressable, StyleSheet, View } from "react-native";
import { CustomText } from "./custom-text-component";
import { AntDesign } from "@expo/vector-icons";
import DateTimePicker from "react-datetime-picker";
import ApiService from "../../service/api-service";
import { Auth } from "aws-amplify";
import { AppStateContext } from "../../util/context/app-context";
import GlobalCSS from "../../util/style";

export const CalendarScheduler = (props) => {
  const { client, setToggleScheduler, refresh } = props;
  const [userInfo, setUserInfo] = useState("");
  const [disable, setDisable] = useState(false);
  const [meetingStartDate, setMeetingStartDate] = useState(new Date());
  const [meetingEndDate, setMeetingEndDate] = useState(new Date());
  const url = `https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23ffffff&ctz=America%2FLos_Angeles&showTitle=0&src=${encodeURI(
    userInfo
  )}&color=%23039BE5&color=%230B8043`;
  const context = useContext(AppStateContext);

  useEffect(() => {
    async function getUserInfo() {
      try {
        const result = await Auth.currentUserInfo();
        setUserInfo(result.attributes.email);
      } catch (error) {
        context.setOverlayState(true, error.toString());
      }
    }

    getUserInfo();
  }, []);

  async function onSubmit() {
    try {
      context.setOverlayState(true);
      console.log("UI - API called to create Zoom meeting");
      setDisable(true);
      const result = await ApiService.createZoomMeeting(
        meetingStartDate,
        meetingEndDate,
        client,
        userInfo
      );
      await ApiService.createCalendarEvent(
        result.zoom_meeting_link,
        client,
        meetingStartDate,
        meetingEndDate
      );
      setToggleScheduler(false);
      refresh();
      context.setOverlayState(false);
    } catch (error) {
      context.setOverlayState(true, error.toString());
    }
  }

  return (
    <View
      style={{
        flex: 1,
        flexDirection: "row",
        width: "100%",
        paddingHorizontal: 15,
      }}
    >
      <View style={[{ flex: 2 }]}>
        <iframe src={url} width="100%" height="100%" />
      </View>
      <View style={styles.actionBox}>
        <View style={[GlobalCSS.center, { flex: 1 }]}>
          <View style={styles.meetingScheduler}>
            <View style={styles.crossMark}>
              <Pressable
                onPress={() => {
                  setToggleScheduler(false);
                }}
              >
                <AntDesign name="close" size={20} color="black" />
              </Pressable>
            </View>
            <CustomText style={{ fontWeight: "bold", fontSize: 20 }}>
              Meeting Start Date and Time:
            </CustomText>
            <DateTimePicker
              onChange={setMeetingStartDate}
              value={meetingStartDate}
              disabled={disable}
            />
            <CustomText style={{ fontWeight: "bold", fontSize: 20 }}>
              Meeting End Date and Time:
            </CustomText>
            <DateTimePicker
              onChange={setMeetingEndDate}
              value={meetingEndDate}
              disabled={disable}
            />
            <Pressable
              style={{
                height: "10%",
                width: "40%",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgb(255, 246, 243)",
                borderWidth: 1,
                borderColor: "rgb(249, 198, 181)",
                borderRadius: 30,
                padding: 10,
              }}
              disabled={disable}
              onPress={() => onSubmit()}
            >
              <CustomText
                style={[
                  {
                    paddingHorizontal: 5,
                    color: "rgb(206, 107, 74)",
                    fontSize: 20,
                  },
                ]}
              >
                Submit
              </CustomText>
            </Pressable>
          </View>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  actionBox: {
    flex: 1,
    justifyContent: "space-between",
  },
  crossMark: {
    width: "100%",
    paddingHorizontal: "5%",
    alignItems: "flex-end",
    zIndex: 1,
  },
  meetingScheduler: {
    width: "80%",
    height: "40%",
    margin: 40,
    borderRadius: 30,
    backgroundColor: "white",
    justifyContent: "space-around",
    alignItems: "center",
  },
});
