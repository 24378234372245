import * as React from "react";
import Svg, { G, Path } from "react-native-svg";

const DesktopIcon = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 373.42 373.35"
    {...props}
  >
    <G data-name="Layer 2">
      <G data-name="Layer 1">
        <Path d="m369.21 41.84-.41-4.32c-1.58-18.43-18.9-32.66-41.19-33.77l-5.83-.29a2640.1 2640.1 0 0 0-269.85 0l-5.83.3C23.81 4.86 6.49 19.09 4.92 37.52l-.42 4.32a1202.26 1202.26 0 0 0 0 208l.41 4.26c1.57 18.49 19 32.67 41.24 33.77l11.08.58c43.29 2.1 86.92 3.21 130.55 3.21 46.67 0 93.33-1.23 139.59-3.68 22.17-1.16 39.49-15.39 40.83-33.77l.42-4.37a1203.26 1203.26 0 0 0 .59-208Zm-23.33 206-.36 4.38c-.58 6.35-9.16 11.95-19.07 12.48a2630.6 2630.6 0 0 1-267.75.46l-11-.52c-10-.53-18.61-5.84-19.19-12.49l-.36-4.31a1179.77 1179.77 0 0 1 0-204.17l.36-4.38c.58-6.36 9.16-12 19.13-12.48l5.83-.3c44.22-2 88.9-3.14 133.58-3.14s89.37 1.11 133.76 3.38l5.83.3c10 .52 18.55 6.12 19.14 12.48l.35 4.37a1179 1179 0 0 1-.18 203.93ZM277 306.79a11.64 11.64 0 0 0-8.28-3.44H105.36a11.68 11.68 0 0 0-8.29 3.44l-46.66 46.67a11.67 11.67 0 0 0 8.28 19.9h256.66a11.67 11.67 0 0 0 8.29-19.9ZM86.87 350l23.33-23.33h153.65L287.18 350Z" />
      </G>
    </G>
  </Svg>
);

export default DesktopIcon;
