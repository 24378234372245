import * as React from "react";
import Svg, { G, Path } from "react-native-svg";

const MobileIcon = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 274.51 473.37"
    {...props}
  >
    <G data-name="Layer 2">
      <G data-name="Layer 1">
        <Path d="M236.71 0h-199A37.8 37.8 0 0 0 0 38v397.6a37.8 37.8 0 0 0 37.8 37.75h198.91a37.81 37.81 0 0 0 37.8-37.75V38a37.8 37.8 0 0 0-37.8-38Zm-199 22.4h199a15.4 15.4 0 0 1 15.4 15.6v17.61H22.51V38a15.4 15.4 0 0 1 15.24-15.6Zm214.34 55.38v272.78H22.45V77.73ZM236.71 451.3h-199a15.38 15.38 0 0 1-15.31-15.68V373H252v62.66a15.42 15.42 0 0 1-15.29 15.63Z" />
        <Path d="M158.26 410.48a21 21 0 1 1-21-21 21 21 0 0 1 21 21" />
      </G>
    </G>
  </Svg>
);

export default MobileIcon;
