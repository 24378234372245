const Constants = {
  NAVIGATION_OPTIONS: {
    DASHBOARD: {
      display_name: "Dashboard",
      screen_name: "dashboard",
      isShown: true,
    },
    PROVIDERS: {
      display_name: "HCP",
      screen_name: "providers",
      isShown: true,
    },
    CALENDAR: {
      display_name: "Calendar",
      screen_name: "calendar",
      isShown: true,
    },
    SUPPORT: {
      display_name: "Support",
      screen_name: "support",
      isShown: true,
    },
    ACCOUNT: {
      display_name: "Account",
      screen_name: "account",
      isShown: true,
    },
    CUSTOMER_DETAILS: {
      display_name: "Customer Detail",
      screen_name: "customer_details",
      isShown: false,
    },
  },
  STYLES: {
    LIGHT_GREY: "#F2F8FD",
    BLUE: "#5F7999",
  },
  ENGAGEMENT_TYPE: {
    ORDER_HISTORY: {
      label: "Order History",
      value: "ORDER_HISTORY",
    },
    CALL_HISTORY: {
      label: "Call History",
      value: "CALL_HISTORY",
    },
    CAMPAIGN: {
      label: "Campaign",
      value: "CAMPAIGN",
    },
    EVENT_HISTORY: {
      label: "Event History",
      value: "EVENT_HISTORY",
    },
    PAGE_VIEW_HISTORY: {
      label: "Page View History",
      value: "PAGE_VIEW_HISTORY",
    },
    DOWNLOAD_HISTORY: {
      label: "Download History",
      value: "DOWNLOAD_HISTORY",
    },
    CHAT_HISTORY: {
      label: "Chat History",
      value: "CHAT_HISTORY",
    },
  },
};
export default Constants;
