import { StyleSheet } from "react-native";
import { useMediaQuery } from "react-responsive";

const screen_resolution = {
  LARGE: 1700,
  MEDIUM: 968,
  SMALL: 768,
};

export default function CreateResponsiveStyle(
  commonDeviceStyles = {},
  largeDeviceStyles = {},
  mediumDeviceStyles = {},
  smallDeviceStyles = {}
) {
  const common = StyleSheet.create(commonDeviceStyles);
  const large = StyleSheet.create(largeDeviceStyles);
  const medium = StyleSheet.create(mediumDeviceStyles);
  const small = StyleSheet.create(smallDeviceStyles);

  // Return a function that combines wraps web and mobile styles
  return (layout) => {
    return (style) => {
      if (layout.width > screen_resolution.LARGE) {
        return StyleSheet.compose(common[style], large[style]);
      } else if (
        layout.width > screen_resolution.MEDIUM &&
        layout.width < screen_resolution.LARGE
      ) {
        return StyleSheet.compose(common[style], medium[style]);
      } else {
        return StyleSheet.compose(common[style], small[style]);
      }
    };
  };
}

const Large = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: screen_resolution.LARGE });
  return isDesktop ? children : null;
};
const Medium = ({ children }) => {
  const isTablet = useMediaQuery({
    minWidth: screen_resolution.MEDIUM,
    maxWidth: screen_resolution.LARGE - 1,
  });
  return isTablet ? children : null;
};
const Small = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: screen_resolution.MEDIUM - 1 });
  return isMobile ? children : null;
};

export { Large, Medium, Small };
