import React from "react";
import { Cell, Legend, Pie, PieChart } from "recharts";
import GlobalCSS from "../../util/style";
import { CustomText } from "../common/custom-text-component";
import CreateResponsiveStyle from "../../util/media-query-wrapper";
import { useWindowDimensions, View } from "react-native";
import { isEmpty } from "lodash";

const COLORS = ["#92b0e5", "#eaa7bf", "#b0e592", "#b1a7ea"];

const RingChart = (props) => {
  const { data = [], title, type } = props;
  const layout = useWindowDimensions();
  const styles = responsiveStyle(layout);
  let hasNoStat = false;
  let showLegend = true,
    innerRadius = 75,
    outerRadius = 80;

  if (type === "meter") {
    showLegend = false;
    innerRadius = 75;
    outerRadius = 80;
  }
  const getCells = data.map((entry, index) => (
    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
  ));

  if (data.every((item) => item.value === 0)) {
    hasNoStat = true;
  }

  return (
    <>
      <CustomText style={[GlobalCSS.h2, styles("header")]}>{title}</CustomText>
      {(isEmpty(data) || hasNoStat) && (
        <View
          style={{
            width: 400,
            height: 300,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CustomText style={[GlobalCSS.h2, styles("header")]}>
            No Data Shown
          </CustomText>
        </View>
      )}
      {!isEmpty(data) && !hasNoStat && (
        <PieChart width={400} height={300}>
          <Pie
            data={data}
            innerRadius={innerRadius}
            outerRadius={outerRadius}
            paddingAngle={2}
            fill="#DBDFE8"
            dataKey={"value"}
          >
            {getCells}
          </Pie>
          {showLegend && (
            <Legend
              verticalAlign="bottom"
              width={"auto"}
              layout={"vertical"}
              formatter={(value, entry) => {
                const { color, payload } = entry;
                return (
                  <>
                    <CustomText>
                      {`${Math.round(payload.percent * 100000) / 1000}% `}
                    </CustomText>
                    <CustomText style={{ marginRight: 4 }}>
                      {payload.value}
                    </CustomText>
                    <CustomText>{value}</CustomText>
                  </>
                );
              }}
            />
          )}
        </PieChart>
      )}
    </>
  );
};
export default RingChart;

const responsiveStyle = CreateResponsiveStyle(
  {
    header: {
      fontSize: 17,
    },
  },
  {},
  {
    header: {
      fontSize: 15,
    },
  }
);
