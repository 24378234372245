import React, { useEffect, useState } from "react";
import { Pressable, ScrollView, useWindowDimensions, View } from "react-native";
import { CustomText } from "./custom-text-component";
import { CustomAccordion } from "./custom-accordion-component";
import { DropdownSingleComponent } from "./dropdown-single-component";
import Constant from "../../util/constants";
import { cloneDeep, isEmpty, isNil } from "lodash";
import { DropdownMultipleComponent } from "./dropdown-multiple-component";
import { GeneralTimelineComponent } from "./sub-timeline/general-timeline-component";
import { PageViewTimelineComponent } from "./sub-timeline/pageview-timeline-component";
import { ChatHistoryTimelineComponent } from "./sub-timeline/chat-history-timeline-component";
import CreateResponsiveStyle from "../../util/media-query-wrapper";
import { DownloadHistoryTimelineComponent } from "./sub-timeline/download-history-timeline-component";

const { STYLES } = Constant;
const { LIGHT_GREY, BLUE } = STYLES;

export const TimelineDetailInfo = (props) => {
  const { data } = props;
  const MONTHS = [
    { label: "Jan", value: 1, labelLong: "January" },
    { label: "Feb", value: 2, labelLong: "February" },
    { label: "Mar", value: 3, labelLong: "March" },
    { label: "Apr", value: 4, labelLong: "April" },
    { label: "May", value: 5, labelLong: "May" },
    { label: "Jun", value: 6, labelLong: "June" },
    { label: "Jul", value: 7, labelLong: "July" },
    { label: "Aug", value: 8, labelLong: "August" },
    { label: "Sep", value: 9, labelLong: "September" },
    { label: "Oct", value: 10, labelLong: "October" },
    { label: "Nov", value: 11, labelLong: "November" },
    { label: "Dec", value: 12, labelLong: "December" },
  ];
  const [activeTab, setActiveTab] = useState(
    Constant.ENGAGEMENT_TYPE.ORDER_HISTORY
  );
  const [yearDropdown, setYearDropdown] = useState([]);
  const [monthDropdown, setMonthDropdown] = useState([]);
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedMonths, setSelectedMonths] = useState([]);
  const layout = useWindowDimensions();
  const styles = responsiveStyle(layout);

  useEffect(() => {
    const latestYear = changeYearDropdownAndDefault();
    changeMonthsDropdownAndDefault(latestYear);
  }, [activeTab]);

  function tabOnPressed(type) {
    setActiveTab(type);
  }

  function changeYearDropdownAndDefault() {
    let recentYear = 0;
    let tempYear = [];
    const displayData = data[activeTab.value];

    if (isEmpty(displayData)) {
      setYearDropdown([]);
      setSelectedYear("");
      return null;
    }

    Object.keys(displayData).forEach((year) => {
      if (year > recentYear) {
        recentYear = year;
      }
    });
    Object.keys(displayData).forEach((year) => {
      tempYear.push({
        label: year,
        value: year,
        isSelected: recentYear === year,
      });
    });
    setYearDropdown(tempYear);
    setSelectedYear(recentYear);
    return recentYear;
  }

  function changeMonthsDropdownAndDefault(recentYear) {
    const displayData = data[activeTab.value];
    if (isEmpty(displayData)) {
      setMonthDropdown([]);
      setSelectedMonths([]);
      return null;
    }

    let monthsClone = cloneDeep(MONTHS);

    const monthsArr = Object.keys(displayData[recentYear]);
    const latest3Months = monthsArr.slice(-3);
    const result = monthsClone.filter((month) =>
      monthsArr.includes(month.value.toString())
    );
    result.forEach((value, index) => {
      if (latest3Months.includes(value.value.toString())) {
        result[index].isSelected = true;
      }
    });
    setMonthDropdown(result);
    setSelectedMonths(result);
  }

  function renderAccordions() {
    const displayData = data[activeTab.value];
    if (
      isEmpty(displayData) ||
      isNil(selectedYear) ||
      isEmpty(selectedMonths) ||
      isNil(displayData[selectedYear])
    ) {
      return null;
    }

    return selectedMonths.map((m, index) => {
      const monthData = displayData[selectedYear][m.value];
      if (isNil(monthData) || m.isSelected !== true) {
        return null;
      }

      return (
        <Accordion
          title={m.labelLong}
          key={index}
          data={monthData}
          type={activeTab.value}
        />
      );
    });
  }

  function renderTabs() {
    return Object.keys(Constant.ENGAGEMENT_TYPE).map((type, index) => {
      return (
        <Pressable
          key={index}
          style={[styles("category"), { borderTopLeftRadius: 30 }]}
          onPress={() => tabOnPressed(Constant.ENGAGEMENT_TYPE[type])}
        >
          <View
            style={[
              {
                padding: 10,
              },
              activeTab.value === Constant.ENGAGEMENT_TYPE[type].value
                ? styles("activeTab")
                : {},
            ]}
          >
            <CustomText
              style={[
                styles("font"),
                { fontWeight: "bold" },
                activeTab.value === Constant.ENGAGEMENT_TYPE[type].value
                  ? styles("activeFont")
                  : {},
              ]}
            >
              {Constant.ENGAGEMENT_TYPE[type].label}
            </CustomText>
          </View>
        </Pressable>
      );
    });
  }

  return (
    <View
      style={{
        flex: 1,
        backgroundColor: "white",
        borderRadius: 30,
        paddingHorizontal: "1rem",
      }}
    >
      <View style={styles("header")}>{renderTabs()}</View>
      <View style={styles("body")}>
        <ScrollView style={{ width: "100%" }}>
          <View style={{ flex: 1, rowGap: "1rem", paddingVertical: "1rem" }}>
            <View
              style={{
                flex: 1,
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <View style={{ width: "15%", marginRight: 15 }}>
                <DropdownSingleComponent
                  dropdownList={yearDropdown}
                  setSelected={(selectedYear) => setSelectedYear(selectedYear)}
                />
              </View>
              <View style={{ width: "15%" }}>
                <DropdownMultipleComponent
                  dropdownList={monthDropdown}
                  showTick={true}
                  setSelected={(monthList) =>
                    setSelectedMonths(cloneDeep(monthList))
                  }
                />
              </View>
            </View>
            {renderAccordions()}
          </View>
        </ScrollView>
      </View>
    </View>
  );
};

const Accordion = (props) => {
  const { title, data, type } = props;
  const layout = useWindowDimensions();
  const styles = responsiveStyle(layout);
  let timeline;

  switch (type) {
    case Constant.ENGAGEMENT_TYPE.PAGE_VIEW_HISTORY.value:
      timeline = <PageViewTimelineComponent data={data} />;
      break;
    case Constant.ENGAGEMENT_TYPE.CHAT_HISTORY.value:
      timeline = <ChatHistoryTimelineComponent data={data} />;
      break;
    case Constant.ENGAGEMENT_TYPE.DOWNLOAD_HISTORY.value:
      timeline = <DownloadHistoryTimelineComponent data={data} />;
      break;
    default:
      timeline = <GeneralTimelineComponent data={data} />;
  }

  return (
    <View style={styles("toggleBody")}>
      <CustomAccordion
        viewInside={<View style={{ width: "100%" }}>{timeline}</View>}
        title={title}
      />
    </View>
  );
};

const responsiveStyle = CreateResponsiveStyle(
  {
    header: {
      flexDirection: "row",
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
      borderStyle: "solid",
      borderBottomWidth: 2,
      borderColor: "#9d9d9d",
    },
    body: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      paddingBottom: 15,
    },
    activeTab: {
      backgroundColor: "rgb(255, 246, 243)",
      borderWidth: 1,
      borderColor: "rgb(249, 198, 181)",
      borderRadius: 30,
    },
    activeFont: {
      color: "rgb(206, 107, 74)",
    },
    category: {
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
      paddingVertical: 20,
    },
    toggleBody: {
      zIndex: -1,
    },
    font: {
      color: "#9d9d9d",
      fontSize: 17,
    },
  },
  {},
  {
    font: {
      fontSize: 15,
    },
  }
);
