import React, { useContext, useEffect, useState } from "react";
import { Pressable, useWindowDimensions, View } from "react-native";
import { CustomText } from "./custom-text-component";
import AccountIcon from "./svg/account-icon";
import AlertIcon from "./svg/alert-icon";
import { Auth } from "aws-amplify";
import { AppStateContext } from "../../util/context/app-context";
import { isEmpty } from "lodash";
import CreateResponsiveStyle from "../../util/media-query-wrapper";
import { NavigationTabs } from "../navigation/navigation-tabs";
import * as RootNavigation from "../navigation/RootNavigation";
import Constants from "../../util/constants";

export const HeaderComponent = () => {
  const [email, setEmail] = useState("");
  const [firstname, setFirstname] = useState(null);
  const [lastname, setLastname] = useState(null);
  const context = useContext(AppStateContext);
  const layout = useWindowDimensions();
  const styles = responsiveStyle(layout);

  useEffect(() => {
    async function getUserInfo() {
      try {
        const result = await Auth.currentUserInfo();
        setFirstname(result.attributes.given_name);
        setLastname(result.attributes.family_name);
        setEmail(result.attributes.email);
      } catch (error) {
        context.setOverlayState(true, error.toString());
      }
    }

    getUserInfo();
  }, []);

  return (
    <View style={styles("container")}>
      <View style={{ flex: 1, flexDirection: "row" }}>
        <View style={{ flex: 9, justifyContent: "center" }}>
          <CustomText style={styles("headerFont")}>{context.header}</CustomText>
        </View>
        <View
          style={{
            flex: 2,
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <View style={{ alignItems: "flex-end" }}>
            {isEmpty(firstname) && isEmpty(lastname) ? (
              <CustomText style={styles("font")}>{email}</CustomText>
            ) : (
              <CustomText
                style={styles("font")}
              >{`${firstname} ${lastname}`}</CustomText>
            )}
          </View>
          <View
            style={{ width: "10%", alignItems: "flex-end", marginLeft: "1rem" }}
          >
            <AccountIcon width="50%" height="50%" fill={"black"} />
          </View>
          <Pressable
            onPress={() => {
              context.setNavigationTabActiveId(0);
              RootNavigation.navigate(
                Constants.NAVIGATION_OPTIONS.DASHBOARD.screen_name
              );
            }}
            style={{ width: "10%", alignItems: "flex-end", marginLeft: "1rem" }}
          >
            {context.hasAlert && <View style={styles("dot")} />}
            <AlertIcon width="50%" height="50%" fill={"black"} />
          </Pressable>
        </View>
      </View>
      <View style={{ flex: 1 }}>
        <NavigationTabs />
      </View>
    </View>
  );
};

const responsiveStyle = CreateResponsiveStyle(
  {
    container: {
      flex: 1,
      paddingVertical: 15,
    },
    headerFont: {
      fontSize: 22,
      fontWeight: "bold",
    },
    dot: {
      backgroundColor: "orange",
      width: 35 / 4,
      height: 35 / 4,
      borderRadius: 35 / 4,
      top: 0,
      right: 0,
      position: "absolute",
    },
    font: {
      fontSize: 17,
      fontWeight: "bold",
    },
  },
  {},
  {
    font: {
      fontSize: 15,
    },
    headerFont: {
      fontSize: 20,
    },
    dot: {
      width: 35 / 6,
      height: 35 / 6,
      borderRadius: 35 / 6,
    },
  }
);

export default HeaderComponent;
