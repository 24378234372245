import * as React from "react"
import Svg, { Path } from "react-native-svg"

const GoogleIcon = (props) => (
    <Svg
        xmlns="http://www.w3.org/2000/svg"
        width={47.81}
        height={48.79}
        viewBox="0 0 50 50"
        {...props}
    >
        <Path
            d="M16.42 1.34c-5.94 2.03-11 6.48-13.82 12.1-.98 1.94-1.69 4-2.12 6.14-1.08 5.3-.33 10.95 2.11 15.78 1.59 3.15 3.87 5.95 6.62 8.14 2.61 2.07 5.64 3.62 8.86 4.47 4.07 1.09 8.39 1.06 12.49.13 3.7-.85 7.2-2.62 9.99-5.19 2.95-2.71 5.06-6.28 6.18-10.12 1.22-4.19 1.38-8.66.62-12.96H24.4v9.52h13.3c-.51 3.04-2.32 5.82-4.88 7.54-1.61 1.09-3.46 1.78-5.38 2.12-1.91.33-3.91.37-5.82-.01-1.95-.39-3.8-1.21-5.43-2.35a15.09 15.09 0 0 1-5.59-7.49c-1.04-3.06-1.05-6.45 0-9.49.73-2.15 1.94-4.13 3.54-5.76 1.97-2.02 4.52-3.46 7.29-4.05 2.36-.5 4.85-.41 7.16.29 1.97.59 3.77 1.68 5.26 3.09 1.5-1.49 2.99-2.99 4.49-4.48.78-.8 1.61-1.57 2.37-2.4-2.27-2.1-4.94-3.79-7.85-4.87-5.25-1.94-11.15-1.98-16.43-.15"
            style={{
                fill: "#fff",
            }}
        />
        <Path
            d="M16.42 1.34c5.27-1.82 11.19-1.79 16.43.14 2.91 1.08 5.57 2.76 7.85 4.87-.76.83-1.59 1.6-2.37 2.4-1.5 1.49-2.99 2.99-4.49 4.48a13.423 13.423 0 0 0-5.26-3.1c-2.31-.69-4.8-.8-7.16-.29-2.75.59-5.31 2.03-7.29 4.05-1.6 1.61-2.81 3.61-3.54 5.75-2.66-2.07-5.33-4.13-8-6.2 2.83-5.62 7.89-10.07 13.83-12.1"
            style={{
                fill: "#ea4335",
            }}
        />
        <Path
            d="M.49 19.56c.43-2.13 1.14-4.2 2.13-6.14 2.66 2.07 5.32 4.13 8 6.2a14.689 14.689 0 0 0 0 9.49c-2.66 2.07-5.32 4.14-7.99 6.2C.16 30.51-.58 24.86.49 19.56"
            style={{
                fill: "#fbbc05",
            }}
        />
        <Path
            d="M24.4 19.81h22.95c.76 4.29.59 8.76-.62 12.96-1.12 3.84-3.22 7.41-6.18 10.13-2.58-2.01-5.17-4.02-7.75-6.02 2.56-1.72 4.37-4.5 4.88-7.54h-13.3c.01-3.17.01-6.35.01-9.52"
            style={{
                fill: "#4285f4",
            }}
        />
        <Path
            d="M2.6 35.34c2.66-2.06 5.33-4.13 7.99-6.2 1.02 3 2.99 5.66 5.59 7.49 1.63 1.14 3.49 1.95 5.43 2.35 1.91.39 3.89.34 5.82.01 1.91-.34 3.76-1.04 5.38-2.12 2.58 2.01 5.17 4.01 7.75 6.02-2.79 2.58-6.29 4.34-9.99 5.19-4.09.93-8.42.96-12.48-.13-3.23-.85-6.26-2.39-8.87-4.47a24.611 24.611 0 0 1-6.61-8.13"
            style={{
                fill: "#34a853",
            }}
        />
    </Svg>
)

export default GoogleIcon
