import React from "react";
import { Auth } from "aws-amplify";
import {
  appendArray,
  appendObject,
  isJsonString,
  sortByDate,
} from "../util/method";
import moment from "moment";
import { cloneDeep, isEmpty, isNil, get } from "lodash";

function appendEngagementObj(obj, objTobeAppended) {
  obj.map((item) => {
    if (!isEmpty(item)) {
      const date = moment.utc(item.timestamp).local();
      const yearObj = appendObject(objTobeAppended, null, date.year());
      appendArray(yearObj, item, date.month() + 1);
    }
  });
  sortByDate(objTobeAppended);
}

const today = moment().startOf("day");
const lastMonth = moment().subtract(1, "month").startOf("month");
const thisMonth = moment().startOf("month");
const nextMonth = moment().add(1, "month").startOf("month");

function isWithinToday(timestamp) {
  return timestamp.isAfter(today) && timestamp.isBefore(today.endOf("day"));
}

function isWithinLastMonth(timestamp) {
  return timestamp.isAfter(lastMonth) && timestamp.isBefore(thisMonth);
}

function isWithinThisMonth(timestamp) {
  return timestamp.isAfter(thisMonth) && timestamp.isBefore(nextMonth);
}

const ApiService = {
  async getProfileList() {
    try {
      let myHeaders = new Headers();
      const token = (await Auth.currentSession())
        .getAccessToken()
        .getJwtToken();
      myHeaders.append("Authorization", token);

      let requestOptions = {
        method: "GET",
        headers: myHeaders,
      };

      console.log("Service - API call to get Profiles");
      return fetch("https://interaction-server.com/profiles", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          return result.profiles.map((person) => {
            return {
              name: person.name[0],
              email: person.email[0],
              td_client_id: person.td_client_id,
              phone: person.phone[0],
              appointments: person.appointments || [],
              inventory: person.inventory || [],
            };
          });
        })
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      throw error;
    }
  },

  async getProfile(email) {
    try {
      let myHeaders = new Headers();
      const token = (await Auth.currentSession())
        .getAccessToken()
        .getJwtToken();
      myHeaders.append("Authorization", token);
      myHeaders.append("Content-Type", "application/json");

      let requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          key: "email",
          value: email,
        }),
      };

      console.log(`Service - API call to get Profile with params: ${email}`);
      const response = await fetch(
        "https://interaction-server.com/adl_response",
        requestOptions
      );
      const result = await response.json();
      const innerTemplate = {
        value: 0,
        name: "",
      };
      const template = {
        this_month: {},
        last_month: {},
        today: {},
        total: {},
      };
      const processedData = {
        ORDER_HISTORY: {},
        PAGE_VIEW_HISTORY: {},
        EVENT_HISTORY: {},
        DOWNLOAD_HISTORY: {},
        CALL_HISTORY: {},
        DRUG_CATEGORY: [],
        CLTV: [],
        UP_COMING_APT: [],
        CALLS_STAT: [],
        EVENTS_STAT: [],
        CHAT_HISTORY: {},
        CAMPAIGN: {},
        SAMPLES_VS_INVENTORY_STAT: [],
        CONSENT_HISTORY: [],
        NEXT_BEST_SUGGESTIONS: [],
      };
      // order history
      if (!isEmpty(result.order_history)) {
        appendEngagementObj(result.order_history, processedData.ORDER_HISTORY);
      }
      // page view history
      if (!isEmpty(result.pageviews_history)) {
        appendEngagementObj(
          result.pageviews_history,
          processedData.PAGE_VIEW_HISTORY
        );
      }
      // campaign data
      if (!isEmpty(result.campaign_data)) {
        const campaignObjList = result.campaign_data.campaign_name.map(
          (item, index) => {
            return {
              timestamp: get(result, `campaign_data.datetime[${index}]`),
              name: get(result, `campaign_data.campaign_name[${index}]`),
              email_opened: get(
                result,
                `campaign_data.campaign_email_opened[${index}]`
              ),
              email_sent: get(
                result,
                `campaign_data.campaign_email_sent[${index}]`
              ),
            };
          }
        );
        appendEngagementObj(campaignObjList, processedData.CAMPAIGN);
      }
      // event history
      if (
        !isEmpty(result.events_history) &&
        !isNil(get(result, "events_history.td_title"))
      ) {
        const eventObjList = result.events_history.td_title.map(
          (item, index) => {
            return {
              timestamp: get(result, `events_history.adl_timestamp[${index}]`),
              title: get(result, `events_history.td_title[${index}]`),
              rsvp: get(result, `events_history.RSVP[${index}]`),
              status: get(result.events_history, `tribe_tickets[order_status]`)[
                index
              ],
              quantity: get(result.events_history, `tribe_tickets[quantity]`)[
                index
              ],
              ticket_id: get(result.events_history, `tribe_tickets[ticket_id]`)[
                index
              ],
            };
          }
        );
        appendEngagementObj(eventObjList, processedData.EVENT_HISTORY);
      }
      // download history
      if (
        !isEmpty(result.downloads_history) &&
        !isNil(get(result, "downloads_history.download_link"))
      ) {
        const downloadHistoryObjList =
          result.downloads_history.download_link.map((item, index) => {
            const link = get(
              result,
              `downloads_history.download_link[${index}]`
            );
            return {
              timestamp: get(
                result,
                `downloads_history.adl_timestamp[${index}]`
              ),
              link: !isNil(link) ? link.split("?")[0] : "",
            };
          });
        appendEngagementObj(
          downloadHistoryObjList,
          processedData.DOWNLOAD_HISTORY
        );
      }
      // call history
      if (
        !isEmpty(result.call_history) &&
        !isNil(get(result, "call_history.join_url"))
      ) {
        const callHistoryObjList = result.call_history.join_url.map(
          (item, index) => {
            const startTime = moment
              .utc(get(result, `call_history.start_time[${index}]`))
              .local();
            const endTime = moment
              .utc(get(result, `call_history.end_time[${index}]`))
              .local();
            const url = get(result, `call_history.join_url[${index}]`);
            return {
              timestamp: get(result, `call_history.created_at[${index}]`),
              link: !isNil(url) ? url.split("?")[0] : "",
              duration: `${endTime.diff(startTime, "minutes")} mins`,
              start: startTime.format("L LT"),
              end: endTime.format("L LT"),
            };
          }
        );
        appendEngagementObj(callHistoryObjList, processedData.CALL_HISTORY);
      }
      // drug category
      if (!isEmpty(result.drug_category)) {
        const cloneTemplate = cloneDeep(template);
        result.drug_category.forEach((drug_cat) => {
          const { category = [], quantity = [] } = drug_cat;
          const timestamp = moment.utc(drug_cat.timestamp).local();

          category.forEach((drug, index) => {
            const drugQuantity = parseInt(quantity[index]);
            if (isWithinToday(timestamp)) {
              if (isNil(cloneTemplate.today[drug])) {
                const cloneTemp = cloneDeep(innerTemplate);
                cloneTemplate.today[drug] = { ...cloneTemp, name: drug };
              }
              cloneTemplate.today[drug].value += drugQuantity;
            }
            if (isWithinLastMonth(timestamp)) {
              if (isNil(cloneTemplate.last_month[drug])) {
                const cloneTemp = cloneDeep(innerTemplate);
                cloneTemplate.last_month[drug] = { ...cloneTemp, name: drug };
              }
              cloneTemplate.last_month[drug].value += drugQuantity;
            }
            if (isWithinThisMonth(timestamp)) {
              if (isNil(cloneTemplate.this_month[drug])) {
                const cloneTemp = cloneDeep(innerTemplate);
                cloneTemplate.this_month[drug] = { ...cloneTemp, name: drug };
              }
              cloneTemplate.this_month[drug].value += drugQuantity;
            }
            if (isNil(cloneTemplate.total[drug])) {
              const cloneTemp = cloneDeep(innerTemplate);
              cloneTemplate.total[drug] = { ...cloneTemp, name: drug };
            }
            cloneTemplate.total[drug].value += drugQuantity;
          });
        });

        Object.keys(cloneTemplate).map((key) => {
          cloneTemplate[key] = Object.values(cloneTemplate[key]).map(
            (inner) => inner
          );
        });
        processedData.DRUG_CATEGORY = cloneTemplate;
      }
      // CLTV
      if (!isEmpty(result.drug_category)) {
        const cltvObj = {
          this_month: [
            {
              value: 0,
              name: "CLTV",
            },
            {
              value: 2000,
              name: "Goals for Sale",
            },
          ],
          last_month: [
            {
              value: 0,
              name: "CLTV",
            },
            {
              value: 2000,
              name: "Goals for Sale",
            },
          ],
          today: [
            {
              value: 0,
              name: "CLTV",
            },
            {
              value: 2000,
              name: "Goals for Sale",
            },
          ],
          total: [
            {
              value: 0,
              name: "CLTV",
            },
            {
              value: 2000,
              name: "Goals for Sale",
            },
          ],
        };

        result.drug_category.forEach((drug_cat) => {
          const { quantity = [], product_name = "" } = drug_cat;
          let batchSize = 1;
          if (!isEmpty(product_name)) {
            const trimmed = product_name.trim();
            const productArr = trimmed.split(" - ");
            const parsedInt = parseInt(productArr[1]);
            if (isNaN(parsedInt)) {
              batchSize = 0;
            } else {
              batchSize = parsedInt;
            }
          }

          const timestamp = moment.utc(drug_cat.timestamp).local();
          quantity.forEach((quan) => {
            const batchQuantity = parseInt(quan) * batchSize;
            cltvObj.total[0].value += batchQuantity;
            if (isWithinToday(timestamp)) {
              cltvObj.today[0].value += batchQuantity;
            }
            if (isWithinLastMonth(timestamp)) {
              cltvObj.last_month[0].value += batchQuantity;
            }
            if (isWithinThisMonth(timestamp)) {
              cltvObj.this_month[0].value += batchQuantity;
            }
          });
        });
        processedData.CLTV = cltvObj;
      }
      // zoom upcoming appointments
      if (!isEmpty(result.zoom_history)) {
        const disabledMeetings = [];
        const meetings = [];
        result.zoom_history.forEach((apt, index) => {
          let zoomTime = moment.utc(apt.start_time).local();
          let isDisabled = zoomTime.isBefore(moment());
          const meetingDetails = {
            key: index,
            timestamp: zoomTime,
            date: zoomTime.format("dddd, MMMM DD YYYY"),
            time: zoomTime.format("h:mma"),
            zoomLink: apt.join_url,
            isDisabled,
          };

          if (isDisabled) {
            disabledMeetings.push(meetingDetails);
          } else {
            meetings.push(meetingDetails);
          }
        });
        sortByDate(meetings);
        sortByDate(disabledMeetings);
        processedData.UP_COMING_APT = meetings.concat(disabledMeetings);
      }
      // calls scheduled cs taken
      if (!isEmpty(result.calls_scheduled_vs_taken)) {
        const adjustedTemplate = {
          today: [],
          last_month: [],
          this_month: [],
          total: [],
        };
        if (!isEmpty(get(result, "calls_scheduled_vs_taken.calls_scheulded"))) {
          const todayObj = {
            value: 0,
            name: "Call scheduled",
          };
          const monthObj = {
            value: 0,
            name: "Call scheduled",
          };
          const lastMonthObj = {
            value: 0,
            name: "Call scheduled",
          };
          const totalObj = {
            value: 0,
            name: "Call scheduled",
          };
          result.calls_scheduled_vs_taken.calls_scheulded.forEach(
            (scheduled, index) => {
              const scheduledTime = moment
                .utc(
                  get(
                    result,
                    `calls_scheduled_vs_taken.calls_scheduled_time[${index}]`
                  )
                )
                .local();
              if (isWithinToday(scheduledTime)) {
                todayObj.value++;
              }
              if (isWithinLastMonth(scheduledTime)) {
                lastMonthObj.value++;
              }
              if (isWithinThisMonth(scheduledTime)) {
                monthObj.value++;
              }
              totalObj.value++;
            }
          );
          if (todayObj.value > 0) {
            adjustedTemplate.today.push(todayObj);
          }
          if (lastMonthObj.value > 0) {
            adjustedTemplate.last_month.push(lastMonthObj);
          }
          if (monthObj.value > 0) {
            adjustedTemplate.this_month.push(monthObj);
          }
          if (totalObj.value > 0) {
            adjustedTemplate.total.push(totalObj);
          }
        }

        if (!isEmpty(get(result, "calls_scheduled_vs_taken.calls_taken"))) {
          const todayObj = {
            value: 0,
            name: "Call taken",
          };
          const monthObj = {
            value: 0,
            name: "Call taken",
          };
          const lastMonthObj = {
            value: 0,
            name: "Call taken",
          };
          const totalObj = {
            value: 0,
            name: "Call taken",
          };
          result.calls_scheduled_vs_taken.calls_taken.forEach(
            (taken, index) => {
              const takenTime = moment
                .utc(
                  get(
                    result,
                    `calls_scheduled_vs_taken.calls_taken_time[${index}]`
                  )
                )
                .local();
              if (isWithinToday(takenTime)) {
                todayObj.value++;
              }
              if (isWithinLastMonth(takenTime)) {
                lastMonthObj.value++;
              }
              if (isWithinThisMonth(takenTime)) {
                monthObj.value++;
              }
              totalObj.value++;
            }
          );
          if (todayObj.value > 0) {
            adjustedTemplate.today.push(todayObj);
          }
          if (lastMonthObj.value > 0) {
            adjustedTemplate.last_month.push(lastMonthObj);
          }
          if (monthObj.value > 0) {
            adjustedTemplate.this_month.push(monthObj);
          }
          if (totalObj.value > 0) {
            adjustedTemplate.total.push(totalObj);
          }
        }
        processedData.CALLS_STAT = adjustedTemplate;
      }
      // events statistic for widget
      if (
        !isEmpty(result.events_history) &&
        !isNil(get(result, "events_history.td_title"))
      ) {
        const cloneTemplate = cloneDeep(template);
        result.events_history.td_title.forEach((item, index) => {
          const timestamp = moment
            .utc(get(result, `events_history.adl_timestamp[${index}]`))
            .local();
          if (isWithinToday(timestamp)) {
            if (isNil(cloneTemplate.today[item])) {
              const cloneTemp = cloneDeep(innerTemplate);
              cloneTemplate.today[item] = { ...cloneTemp, name: item };
            }
            cloneTemplate.today[item].value++;
          }
          if (isWithinLastMonth(timestamp)) {
            if (isNil(cloneTemplate.last_month[item])) {
              const cloneTemp = cloneDeep(innerTemplate);
              cloneTemplate.last_month[item] = { ...cloneTemp, name: item };
            }
            cloneTemplate.last_month[item].value++;
          }
          if (isWithinThisMonth(timestamp)) {
            if (isNil(cloneTemplate.this_month[item])) {
              const cloneTemp = cloneDeep(innerTemplate);
              cloneTemplate.this_month[item] = { ...cloneTemp, name: item };
            }
            cloneTemplate.this_month[item].value++;
          }
          if (isNil(cloneTemplate.total[item])) {
            const cloneTemp = cloneDeep(innerTemplate);
            cloneTemplate.total[item] = { ...cloneTemp, name: item };
          }
          cloneTemplate.total[item].value++;
        });

        Object.keys(cloneTemplate).map((key) => {
          cloneTemplate[key] = Object.values(cloneTemplate[key]).map(
            (inner) => inner
          );
        });
        processedData.EVENTS_STAT = cloneTemplate;
      }
      // chat history
      if (!isEmpty(get(result, "chat_history.chat"))) {
        const chatHistoryObjList = result.chat_history.chat.map(
          (chat, index) => {
            if (isJsonString(chat)) {
              const date = moment
                .utc(get(result, `chat_history.timestamp[${index}]`))
                .local();
              return {
                chat: JSON.parse(chat),
                timestamp: date,
              };
            }

            return {};
          }
        );
        appendEngagementObj(chatHistoryObjList, processedData.CHAT_HISTORY);
      }
      // Sample vs Inventory statistic
      if (!isEmpty(result.drug_category)) {
        const adjustedTemplate = {
          this_month: {
            inStock: {
              value: 0,
              name: "In Stock",
            },
            sold: {
              value: 0,
              name: "Sold",
            },
          },
          last_month: {
            inStock: {
              value: 0,
              name: "In Stock",
            },
            sold: {
              value: 0,
              name: "Sold",
            },
          },
          today: {
            inStock: {
              value: 0,
              name: "In Stock",
            },
            sold: {
              value: 0,
              name: "Sold",
            },
          },
          total: {
            inStock: {
              value: 0,
              name: "In Stock",
            },
            sold: {
              value: 0,
              name: "Sold",
            },
          },
        };

        result.drug_category.forEach((drug_cat) => {
          const { quantity = [], instock = [] } = drug_cat;
          const timestamp = moment.utc(drug_cat.timestamp).local();
          quantity.forEach((quan) => {
            adjustedTemplate.total.sold.value += parseInt(quan);
            if (isWithinToday(timestamp)) {
              adjustedTemplate.today.sold.value += parseInt(quan);
            }
            if (isWithinLastMonth(timestamp)) {
              adjustedTemplate.last_month.sold.value += parseInt(quan);
            }
            if (isWithinThisMonth(timestamp)) {
              adjustedTemplate.this_month.sold.value += parseInt(quan);
            }
          });
          instock.forEach((inStockNum) => {
            adjustedTemplate.total.inStock.value += parseInt(inStockNum);
            if (isWithinToday(timestamp)) {
              adjustedTemplate.today.inStock.value += parseInt(inStockNum);
            }
            if (isWithinLastMonth(timestamp)) {
              adjustedTemplate.last_month.inStock.value += parseInt(inStockNum);
            }
            if (isWithinThisMonth(timestamp)) {
              adjustedTemplate.this_month.inStock.value += parseInt(inStockNum);
            }
          });
        });

        Object.keys(adjustedTemplate).map((key) => {
          adjustedTemplate[key] = Object.values(adjustedTemplate[key]).map(
            (inner) => inner
          );
        });
        processedData.SAMPLES_VS_INVENTORY_STAT = adjustedTemplate;
      }
      // consent history
      if (
        !isEmpty(get(result, "consent_history.analytics_storage")) ||
        !isEmpty(get(result, "consent_history.allowed"))
      ) {
        let consentObjs = get(result, "consent_history.analytics_storage").map(
          (consent, index) => {
            const timestamp = get(
              result,
              `consent_history.adl_timestamp[${index}]`
            );
            const consentTime = moment.utc(timestamp).local();
            return {
              timestamp: consentTime,
              status: consent,
            };
          }
        );
        if (
          !isEmpty(get(result, "consent_history.allowed")) &&
          isEmpty(get(result, "consent_history.analytics_storage"))
        ) {
          const timestamp = get(result, `consent_history.adl_timestamp.0`);
          const consentTime = moment.utc(timestamp).local();
          consentObjs = [
            {
              timestamp: consentTime,
              status: "Granted",
            },
          ];
        }

        if (
          consentObjs.every(
            (obj) => obj.status === "granted" || consentObjs.length === 1
          )
        ) {
          processedData.CONSENT_HISTORY = [consentObjs[consentObjs.length - 1]];
        } else {
          const temp = [];
          let currentConsent;
          consentObjs.forEach((consent, index) => {
            if (!isEmpty(consent)) {
              if (isNil(currentConsent)) {
                currentConsent = consentObjs;
              }

              if (consent.status !== currentConsent.status) {
                currentConsent = consent;
                temp.push(consentObjs[index]);
              }
            }
          });

          processedData.CONSENT_HISTORY = temp;
        }
      }

      if (!isEmpty(result.next_best_suggestion)) {
        const temp = result.next_best_suggestion.map((item) => {
          const { date_time = "", suggestion } = item;
          let parsedTime;

          if (!isEmpty(date_time)) {
            const date = date_time.substring(0, 11);
            const time = date_time.substring(
              date_time.length - 8,
              date_time.length
            );
            const timeArr = [date, time].join(" ").toString();
            parsedTime = moment.utc(timeArr).local();
          }

          return {
            timestamp: parsedTime || moment(),
            suggestion,
          };
        });
        sortByDate(temp);
        processedData.NEXT_BEST_SUGGESTIONS = temp;
      }

      if (!isEmpty(result.segments_info)) {
        result.segments_info = result.segments_info.reverse();
      }

      return {
        ...result,
        ...processedData,
      };
    } catch (error) {
      throw error;
    }
  },

  sendToken(token) {
    try {
      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      let requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          token,
        }),
      };

      console.log(`Service - API call to send token with params: ${token}`);
      return fetch("https://token-server.com/add", requestOptions)
        .then((response) => response.text())
        .then((result) => {})
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      throw error;
    }
  },

  async createCalendarEvent(meetingLink, client, start, end) {
    try {
      let myHeaders = new Headers();
      const googleToken = (await Auth.currentSession()).idToken.payload[
        "custom:access_token"
      ];
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${googleToken}`);

      let requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          summary: `${client.name} Zoom meeting`,
          description: meetingLink,
          end: {
            dateTime: end,
          },
          start: {
            dateTime: start,
          },
          attendees: [{ email: client.email }],
        }),
      };

      const params = {
        sendNotifications: true,
        sendUpdates: "all",
      };

      const url = new URL(
        "https://www.googleapis.com/calendar/v3/calendars/primary/events"
      );
      Object.entries(params).forEach(([k, v]) => url.searchParams.append(k, v));

      console.log(
        `Service - API call to create meeting calendar with params: ${client.email}`
      );
      return fetch(url, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          return result;
        })
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      throw error;
    }
  },

  async createZoomMeeting(start, end, client, agent_email, duration) {
    try {
      let myHeaders = new Headers();
      const googleToken = (await Auth.currentSession()).idToken.payload[
        "custom:access_token"
      ];
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${googleToken}`);
      let requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          start_time: start,
          end_time: end,
          duration: duration,
          timezone: "UTC",
          email: "anand.kumar@td-testing.com",
          alternative_host_email: "anand.kumar@td-testing.com",
          participant: [
            {
              participant_type: "patient",
              email: client.email,
              td_client_id: client.td_client_id,
            },
            {
              participant_type: "agent",
              email: agent_email,
            },
          ],
        }),
      };

      console.log(
        `Service - API call to create zoom meeting with params: ${JSON.stringify(
          client
        )}`
      );
      return fetch(
        "https://td-zoom-integration.com/create_meeting",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          return result;
        })
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      throw error;
    }
  },

  async createCalendlyZoomMeeting(meeting, client, agentEmail) {
    try {
      let myHeaders = new Headers();
      const googleToken = (await Auth.currentSession()).idToken.payload[
        "custom:access_token"
      ];
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${googleToken}`);

      let requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          created_at: moment().subtract(1, "day"),
          start_time: moment(),
          end_time: moment().add(1, "minute"),
          duration: 1,
          meeting_id: meeting.meeting_id,
          join_url: meeting.join_url,
          timezone: "UTC",
          email: "anand.kumar@td-testing.com",
          alternative_host_email: "anand.kumar@td-testing.com",
          participant: [
            {
              participant_type: "patient",
              email: client.email,
              td_client_id: client.td_client_id,
            },
            {
              participant_type: "agent",
              email: agentEmail,
            },
          ],
        }),
      };

      console.log(
        `Service - API call to create calendly zoom meeting with params: ${JSON.stringify(
          client
        )}`
      );
      return fetch(
        "https://td-zoom-integration.com/calendly_zoom_meeting",
        requestOptions
      ).then((response) => response.json());
    } catch (error) {
      throw error;
    }
  },
};

export default ApiService;
