import React from "react";

export const AppStateContext = React.createContext({
  header: "",
  setHeader: () => {},
  routeChain: [],
  addRouteToChain: () => {},
  setRouteChain: () => {},
  overlayState: { isLoading: false, errorMsg: "" },
  setOverlayState: () => {},
  hasAlert: false,
  setHasAlert: () => {},
  navigationTabActiveId: 0,
  setNavigationTabActiveId: () => {},
});

export default class AppStateProvider extends React.Component {
  state = {
    header: "",
    routeChain: [],
    overlayState: { isLoading: false, errorMsg: "" },
    hasAlert: false,
    navigationTabActiveId: 0,
  };

  setHeader = (header) => {
    this.setState({ header: header });
  };

  addRouteToChain = (route) => {
    this.setState({ routeChain: [...this.state.routeChain, route] });
  };

  setRouteChain = (chain) => {
    this.setState({ routeChain: chain });
  };

  setOverlayState = (isLoading, errorMsg = "") => {
    this.setState({
      overlayState: { isLoading: isLoading, errorMsg: errorMsg },
    });
  };

  setHasAlert = (value) => {
    this.setState({ hasAlert: value });
  };

  setNavigationTabActiveId = (value) => {
    this.setState({ navigationTabActiveId: value });
  };

  render() {
    return (
      <AppStateContext.Provider
        value={{
          ...this.state,
          setHeader: this.setHeader,
          addRouteToChain: this.addRouteToChain,
          setRouteChain: this.setRouteChain,
          setOverlayState: this.setOverlayState,
          setHasAlert: this.setHasAlert,
          setNavigationTabActiveId: this.setNavigationTabActiveId,
        }}
      >
        {this.props.children}
      </AppStateContext.Provider>
    );
  }
}
