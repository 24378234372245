import React, { useContext, useState } from "react";
import { Pressable, StyleSheet, TextInput, View } from "react-native";
import { CustomText } from "../common/custom-text-component";
import { Picker } from "@react-native-picker/picker";
import { Auth } from "aws-amplify";
import { AppStateContext } from "../../util/context/app-context";
import Constants from "../../util/constants";
import GlobalCSS from "../../util/style";

export const SignUpComponent = (props) => {
  const { onPressSignInSubmission } = props;
  const [hasSignUp, setHasSignUp] = useState(false);
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [selectedDivision, setDivision] = useState();
  const [code, setCode] = useState("");
  const context = useContext(AppStateContext);

  async function signUp() {
    try {
      context.setOverlayState(true);
      await Auth.signUp({
        username: email,
        password: password,
        attributes: {
          email: email,
          "custom:division": selectedDivision,
          family_name: lastname,
          given_name: firstname,
        },
      });
      setHasSignUp(true);
      context.setOverlayState(false);
    } catch (error) {
      context.setOverlayState(true, error.toString());
    }
  }

  async function confirmSignUp() {
    try {
      context.setOverlayState(true);
      await Auth.confirmSignUp(email, code);
      onPressSignInSubmission();
      context.setOverlayState(false);
    } catch (error) {
      context.setOverlayState(true, error.toString());
    }
  }

  return (
    <View style={{ flex: 1 }}>
      {!hasSignUp && (
        <View style={[styles.body]}>
          <TextInput
            style={[styles.item, { paddingLeft: 10 }]}
            placeholder={"First name"}
            onChangeText={setFirstname}
          />
          <TextInput
            style={[styles.item, { paddingLeft: 10 }]}
            placeholder={"Last name"}
            onChangeText={setLastname}
          />
          <TextInput
            style={[styles.item, { paddingLeft: 10 }]}
            placeholder={"Email"}
            onChangeText={setEmail}
          />
          <View style={[styles.item, { paddingLeft: 10 }]}>
            <Picker
              style={{ borderWidth: 0, flex: 1, width: "98%" }}
              selectedValue={selectedDivision}
              placeholder={"Select a division"}
              itemStyle={{ height: 50 }}
              onValueChange={(itemValue, itemIndex) => setDivision(itemValue)}
            >
              <Picker.Item label="Sales" value="s" />
              <Picker.Item label="Maintenance" value="m" />
              <Picker.Item label="Finance" value="f" />
            </Picker>
          </View>
          <TextInput
            style={[styles.item, { paddingLeft: 10 }]}
            placeholder={"Password"}
            onChangeText={setPassword}
            secureTextEntry={true}
          />
          <View
            style={{
              flexDirection: "row",
              width: "70%",
              justifyContent: "space-between",
            }}
          >
            <Pressable
              style={[
                {
                  justifyContent: "center",
                  alignItems: "flex-start",
                  width: "30%",
                },
              ]}
              onPress={onPressSignInSubmission}
            >
              <CustomText
                style={[
                  styles.font,
                  {
                    color: Constants.STYLES.BLUE,
                    fontSize: 13,
                    textDecorationLine: "underline",
                  },
                ]}
              >
                Back to Sign In
              </CustomText>
            </Pressable>
            <Pressable
              style={[styles.button, GlobalCSS.center, { width: "50%" }]}
              onPress={async () => {
                await signUp();
              }}
            >
              <CustomText style={[styles.font, { color: "white" }]}>
                Create Account
              </CustomText>
            </Pressable>
          </View>
        </View>
      )}
      {hasSignUp && (
        <View style={[styles.body]}>
          <View
            style={{
              width: "70%",
              height: "50%",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <TextInput
              style={[styles.item, { paddingLeft: 10 }]}
              placeholder={"Enter your confirmation code"}
              onChangeText={setCode}
            />
            <Pressable
              style={[styles.button, GlobalCSS.center, { width: "50%" }]}
              onPress={async () => {
                await confirmSignUp();
              }}
            >
              <CustomText style={[styles.font, { color: "white" }]}>
                Confirm
              </CustomText>
            </Pressable>
          </View>
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  body: {
    paddingHorizontal: 30,
    justifyContent: "space-between",
    width: "100%",
    height: "90%",
    alignItems: "center",
  },
  item: {
    borderWidth: 1,
    borderColor: "grey",
    borderRadius: 9,
    minHeight: 55,
    width: "100%",
  },
  font: {
    fontSize: 16,
  },
  button: {
    backgroundColor: "#5061F7",
    color: "white",
    minHeight: 50,
    borderRadius: 10,
  },
});
