import React, { useContext, useState } from "react";
import { Pressable, StyleSheet, Text, TextInput, View } from "react-native";
import { CustomText } from "../common/custom-text-component";
import { Auth } from "aws-amplify";
import GoogleIcon from "../common/svg/google-icon";
import { AppStateContext } from "../../util/context/app-context";
import Constants from "../../util/constants";
import GlobalCSS from "../../util/style";

export const SignInComponent = (props) => {
  const context = useContext(AppStateContext);
  const { onPressSignUp, onPressResetPassword } = props;
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");

  async function googleSignIn() {
    try {
      context.setOverlayState(true);
      await Auth.federatedSignIn({ provider: "Google" });
      context.setOverlayState(false);
    } catch (error) {
      context.setOverlayState(true, error.toString());
    }
  }

  async function signIn() {
    try {
      context.setOverlayState(true);
      const user = await Auth.signIn(email.trim(), password.trim());

      if (user) {
        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          onPressResetPassword(true, { email, password });
        }
      }
      context.setOverlayState(false);
    } catch (error) {
      context.setOverlayState(true, error.toString());
    }
  }

  return (
    <View style={[styles.body]}>
      <Pressable
        style={[styles.item, { alignItems: "center" }]}
        title={"signed"}
        onPress={() => googleSignIn()}
      >
        <View
          style={{
            height: "100%",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <View style={{ width: 30, marginRight: 10 }}>
            <GoogleIcon height="100%" width="100%" />
          </View>
          <View style={{ alignItems: "flex-start" }}>
            <CustomText style={styles.font}>Sign In with Google</CustomText>
          </View>
        </View>
      </Pressable>
      <View
        style={{ flexDirection: "row", alignItems: "center", width: "70%" }}
      >
        <View style={{ flex: 1, height: 1, backgroundColor: "grey" }} />
        <View>
          <Text
            style={[
              styles.font,
              { width: 50, textAlign: "center", color: "grey" },
            ]}
          >
            OR
          </Text>
        </View>
        <View style={{ flex: 1, height: 1, backgroundColor: "grey" }} />
      </View>
      <TextInput
        style={[styles.item, { paddingLeft: 10 }]}
        placeholder={"EMAIL ADDRESS"}
        onChangeText={setEmail}
      />
      <TextInput
        style={[styles.item, { paddingLeft: 10 }]}
        placeholder={"PASSWORD"}
        onChangeText={setPassword}
        secureTextEntry={true}
      />
      <View style={{ width: "70%", flexDirection: "row" }}>
        <View style={{ flex: 2, flexDirection: "row" }}>
          {/*<Pressable style={[{flex: 1, alignItems: 'flex-start', justifyContent: 'center'}]} onPress={onPressSignUp}>*/}
          {/*    <CustomText style={[styles.font, {color: '#4c69d4', fontSize: 13, textDecorationLine: 'underline'}]}>Sign Up</CustomText>*/}
          {/*</Pressable>*/}
          <Pressable
            style={[
              { flex: 1, alignItems: "flex-start", justifyContent: "center" },
            ]}
            onPress={() => onPressResetPassword()}
          >
            <CustomText
              style={[
                styles.font,
                {
                  color: Constants.STYLES.BLUE,
                  fontSize: 13,
                  textDecorationLine: "underline",
                },
              ]}
            >
              Reset Password
            </CustomText>
          </Pressable>
        </View>
        <View style={{ flex: 2, flexDirection: "row" }}>
          <Pressable
            style={[
              styles.button,
              GlobalCSS.center,
              {
                flex: 1,
                width: "50%",
              },
            ]}
            onPress={async () => {
              await signIn();
            }}
          >
            <CustomText style={[styles.font, { color: "white" }]}>
              Sign In
            </CustomText>
          </Pressable>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  body: {
    paddingHorizontal: 30,
    justifyContent: "space-between",
    width: "100%",
    height: "90%",
    alignItems: "center",
  },
  item: {
    borderWidth: 1,
    borderColor: "grey",
    borderRadius: 9,
    minHeight: 55,
    width: "70%",
  },
  font: {
    fontSize: 16,
  },
  button: {
    backgroundColor: "#5061F7",
    color: "white",
    minHeight: 50,
    borderRadius: 10,
  },
});
