import * as React from "react"
import Svg, { Path } from "react-native-svg"

const LogoutIcon = (props) => (
    <Svg
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 72.47 70.79"
        {...props}
    >
        <Path d="M13.75 33.38h51L47 15.55l2.83-2.83L71 34l1.43 1.43L71 36.81 49.78 58.07 47 55.21l17.81-17.8h-51Zm11 37.41H12.66A12.7 12.7 0 0 1 0 58.1V12.66A12.68 12.68 0 0 1 12.66 0h12.09v4H12.66A8.67 8.67 0 0 0 4 12.66V58.1a8.67 8.67 0 0 0 8.63 8.66h12.12v4Z" />
    </Svg>
)

export default LogoutIcon
