import * as React from "react";
import { Image, StyleSheet, Text, TouchableOpacity, View } from "react-native";
import Collapsible from "react-native-collapsible";

const downArrow = require("../../../assets/ic-expand-more.png");
const upArrow = require("../../../assets/ic-expand-less.png");

export const CustomAccordion = ({
  title = "",
  viewInside,
  startCollapsed = false,
  showContentInsideOfCard = true,
  showArrows = true,
  arrowColor = "#000000",
  viewContainerStyle = {},
  bannerStyle = {},
  titleStyle = {},
}) => {
  const [isCollapsed, setIsCollapsed] = React.useState(startCollapsed);

  return (
    <View>
      <TouchableOpacity
        style={[
          styles.bannerStyle,
          isCollapsed
            ? { borderRadius: 30, borderBottomWidth: 1 }
            : { borderTopLeftRadius: 30, borderTopRightRadius: 30 },
          isCollapsed ? styles.not_collapsed : {},
        ]}
        onPress={() => setIsCollapsed(!isCollapsed)}
      >
        <View style={[styles.titleContainer]}>
          <Text allowFontScaling={false} style={[styles.defaultTitleStyle]}>
            {title}
          </Text>
        </View>
        {showArrows && (
          <Image
            source={isCollapsed ? downArrow : upArrow}
            style={[styles.arrows, { tintColor: "#9d9d9d" }]}
          />
        )}
      </TouchableOpacity>
      <Collapsible collapsed={isCollapsed} style={[styles.collapsible]}>
        <View
          style={[
            styles.defaultViewContainer,
            { borderBottomRightRadius: 30, borderBottomLeftRadius: 30 },
          ]}
        >
          {viewInside}
        </View>
      </Collapsible>
    </View>
  );
};

const styles = StyleSheet.create({
  nothing: {},
  arrows: {
    height: 32,
    width: 32,
    resizeMode: "contain",
  },
  bannerStyle: {
    height: 50,
    flexDirection: "row",
    padding: 16,
    alignItems: "center",
    backgroundColor: "white",
    zIndex: 0,
    borderTopWidth: 2,
    borderRightWidth: 2,
    borderLeftWidth: 2,
    borderColor: "#9d9d9d",
  },
  defaultTitleStyle: {
    fontSize: 22,
    fontWeight: "bold",
    color: "#000000",
  },
  defaultViewContainer: {
    padding: 8,
    backgroundColor: "#FFFFFF",
    justifyContent: "center",
    alignItems: "center",
  },
  titleContainer: {
    flex: 1,
    paddingLeft: 50,
  },
  collapsible: {
    paddingBottom: 8,
    borderBottomWidth: 2,
    borderRightWidth: 2,
    borderLeftWidth: 2,
    borderColor: "#9d9d9d",
    borderBottomLeftRadius: 30,
    borderBottomRightRadius: 30,
  },
  not_collapsed: {
    backgroundColor: "white",
  },
});
