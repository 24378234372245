import React, { useContext } from "react";
import { ActivityIndicator, Pressable, StyleSheet, View } from "react-native";
import { AppStateContext } from "../../util/context/app-context";
import { isEmpty } from "lodash";
import { CustomText } from "./custom-text-component";

export const Overlay = (props) => {
  const context = useContext(AppStateContext);
  const { backgroundColor, children } = props;

  function onPressOkButton() {
    context.setOverlayState(false);
  }

  return (
    <View
      style={[
        styles.background,
        !isEmpty(backgroundColor) ? { backgroundColor } : {},
      ]}
    >
      {!isEmpty(context.overlayState.errorMsg) ? (
        <View style={styles.messageDiv}>
          <View
            style={{
              flex: 1,
              width: "100%",
              paddingHorizontal: 30,
              justifyContent: "center",
            }}
          >
            <CustomText style={{ fontWeight: "bold", fontSize: 25 }}>
              Error Occur
            </CustomText>
          </View>
          <View
            style={{
              flex: 3,
              width: "100%",
              paddingHorizontal: 30,
              justifyContent: "center",
            }}
          >
            <CustomText style={{ color: "red", fontSize: 25 }}>
              {context.overlayState.errorMsg}
            </CustomText>
          </View>
          <View
            style={{
              flex: 1,
              width: "100%",
              paddingHorizontal: 30,
              alignItems: "flex-end",
              backgroundColor: "#F4F4F4",
              borderBottomEndRadius: 30,
              borderBottomStartRadius: 30,
              justifyContent: "center",
            }}
          >
            <Pressable onPress={() => onPressOkButton()} style={styles.button}>
              <CustomText style={{ color: "white", fontSize: 25 }}>
                Ok
              </CustomText>
            </Pressable>
          </View>
        </View>
      ) : !isEmpty(children) ? (
        children
      ) : (
        <ActivityIndicator size="large" color="white" />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  background: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: "rgba(120, 120, 120, 0.8)",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1,
  },
  messageDiv: {
    width: "30%",
    height: "20%",
    backgroundColor: "white",
    justifyContent: "space-evenly",
    alignItems: "center",
    borderRadius: 30,
  },
  button: {
    width: "20%",
    height: "2rem",
    backgroundColor: "rgb(41, 83, 129)",
    borderRadius: 30,
    justifyContent: "center",
    alignItems: "center",
  },
});
