import React from "react";
import { StyleSheet, Text } from "react-native";

export const CustomText = (props) => {
  const { onPress, style } = props;

  return (
    <Text style={[styles.defaultStyle, style]} onPress={onPress}>
      {props.children}
    </Text>
  );
};

const styles = StyleSheet.create({
  defaultStyle: {
    fontFamily: "Nunito-Regular",
  },
});
