import * as React from "react";
import Svg, { Defs, LinearGradient, Stop, Path } from "react-native-svg";

const LogoWhiteIcon = (props) => (
  <Svg
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="450 450 1000 400"
    {...props}
  >
    <Defs>
      <LinearGradient
        id="a"
        x1={871.52}
        y1={627.5}
        x2={1024.12}
        y2={363.19}
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#fff" />
        <Stop offset={1} stopColor="#c8c8c8" />
      </LinearGradient>
      <LinearGradient
        id="b"
        x1={983.26}
        y1={456.84}
        x2={1011.07}
        y2={342.06}
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0.07} stopColor="#fff" />
        <Stop offset={1} stopColor="#f0f0f0" />
      </LinearGradient>
      <LinearGradient
        id="c"
        x1={964.11}
        y1={541.89}
        x2={1115.94}
        y2={594.44}
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0.34} stopColor="#c8c8c8" />
        <Stop offset={0.55} stopColor="#d0d0d0" />
        <Stop offset={0.9} stopColor="#e7e7e7" />
        <Stop offset={1} stopColor="#f0f0f0" />
      </LinearGradient>
    </Defs>
    <Path
      d="M973.75 669.87 1155.6 488a19.45 19.45 0 0 0 0-27.5l-47.76-47.76a31.44 31.44 0 0 0-22.23-9.21H834.38a31.41 31.41 0 0 0-22.23 9.21l-47.76 47.76a19.43 19.43 0 0 0 0 27.5l181.85 181.87a19.45 19.45 0 0 0 27.51 0Z"
      style={{
        fill: "url(#a)",
      }}
    />
    <Path
      d="M810.83 414.08a27.68 27.68 0 0 1 25.17-7.56l315.79 64.17a9.66 9.66 0 0 1 6.93 13.3 19.44 19.44 0 0 0-3.07-23.47l-47.76-47.76a31.44 31.44 0 0 0-22.23-9.21H834.38a31.41 31.41 0 0 0-22.23 9.21Z"
      style={{
        opacity: 0.7,
        fill: "url(#b)",
      }}
    />
    <Path
      d="m1091.78 417.4-18.49 37.35 78.45 15.94a9.66 9.66 0 0 1 6.93 13.3 19.44 19.44 0 0 0-3.07-23.47l-47.76-47.76a31.44 31.44 0 0 0-22.23-9.21h-2.43a9.6 9.6 0 0 1 8.6 13.85Z"
      style={{
        fill: "#fff",
      }}
    />
    <Path
      d="M1158.67 484a9.66 9.66 0 0 0-6.93-13.3l-78.45-15.94L968 667.46a13 13 0 0 1-15.44 6.63 19.46 19.46 0 0 0 21.19-4.22L1155.6 488a19.29 19.29 0 0 0 3.07-4Z"
      style={{
        fill: "url(#c)",
      }}
    />
  </Svg>
);

export default LogoWhiteIcon;
