import React from "react";
import { View } from "react-native";
import moment from "moment";
import { CustomText } from "../custom-text-component";
import { beautifyKey } from "../../../util/method";
import Timeline from "react-native-timeline-flatlist";
import Constants from "../../../util/constants";

export const GeneralTimelineComponent = (props) => {
  const { data } = props;

  const content = [];
  data.forEach((entry) => {
    const { timestamp = new Date() } = entry;
    const time = moment.utc(timestamp).local();
    content.push({
      title: (
        <CustomText>
          {time.format("ll")} {time.format("LT")}
        </CustomText>
      ),
      description: (
        <View>
          {Object.keys(entry).map((key, index) => {
            if (key !== "timestamp") {
              return (
                <CustomText key={index} style={{ fontSize: 15 }}>
                  {beautifyKey(key)}: {entry[key]}
                </CustomText>
              );
            }
          })}
        </View>
      ),
    });
  });

  return (
    <Timeline
      style={{ flex: 1 }}
      detailContainerStyle={{ marginBottom: 15 }}
      circleColor={Constants.STYLES.BLUE}
      lineColor={Constants.STYLES.BLUE}
      titleStyle={{ marginBottom: 10 }}
      data={content}
      eventContainerStyle={{ marginTop: -5 }}
    />
  );
};
