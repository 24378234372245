import React, { useContext, useEffect, useState } from "react";
import { FlatList, Pressable, useWindowDimensions, View } from "react-native";
import { CustomText } from "./common/custom-text-component";
import GlobalCSS from "../util/style";
import { AppStateContext } from "../util/context/app-context";
import { addRouteToChain } from "../util/method";
import Constant from "../util/constants";
import { MaterialIcons } from "@expo/vector-icons";
import CreateResponsiveStyle from "../util/media-query-wrapper";
import { cloneDeep, isEmpty, isNil } from "lodash";
import * as RootNavigation from "../components/navigation/RootNavigation";
import moment from "moment";
import ApiService from "../service/api-service";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { useIsFocused } from "@react-navigation/core";
import DatePicker from "react-date-picker";
import { Auth } from "aws-amplify";

const { STYLES } = Constant;

export const DashboardPage = ({ navigation }) => {
  const context = useContext(AppStateContext);
  const template = [
    {
      alertType: "Appointments",
      num: 0,
    },
    {
      alertType: "Inventory",
      num: 0,
    },
  ];
  const [source, setSource] = useState([]);
  const [dateFilter, setDateFilter] = useState({});
  const [dateRangeFilter, setDateRangeFilter] = useState([]);
  const [displayList, setDisplayList] = useState([]);
  const [hasRefresh, setHasRefresh] = useState(true);
  const [showProfiles, setShowProfiles] = useState({ type: "", value: false });
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const layout = useWindowDimensions();
  const styles = responsiveStyle(layout);
  const route = { name: "DASHBOARD", isHead: true };
  const isFocused = useIsFocused();

  useEffect(() => {
    async function getUserInfo() {
      try {
        const result = await Auth.currentUserInfo();
        setFirstName(result.attributes.given_name || "");
        setLastName(result.attributes.family_name || "");
      } catch (error) {
        context.setOverlayState(true, error.toString());
      }
    }

    getUserInfo();
  }, []);

  useEffect(() => {
    if (isFocused) {
      setHasRefresh(!hasRefresh);
    }
    setShowProfiles({ type: "", value: false });
    setDisplayList(template);
  }, [isFocused]);

  useEffect(() => {
    return navigation.addListener("focus", () => {
      context.setHeader("Dashboard");
      const newChain = addRouteToChain(context.routeChain, route);
      context.setRouteChain(newChain);
    });
  }, [navigation]);

  useEffect(() => {
    getData();
  }, [hasRefresh]);

  async function getData() {
    try {
      context.setOverlayState(true);
      console.log("UI - API called to get ProfileList");
      const result = await ApiService.getProfileList();
      setSource(result);
      setDateRangeFilter([new Date(), new Date()]);
      setDateFilter(new Date());
      context.setOverlayState(false);
    } catch (error) {
      context.setOverlayState(true, error.toString());
    }
  }

  useEffect(() => {
    if (showProfiles.value === true) {
      displayProfilesAlerts(showProfiles);
    }
    if (showProfiles.value === false) {
      displayTypeAlerts();
    }
  }, [dateRangeFilter, showProfiles, dateFilter]);

  async function showProfileClicked(alertType) {
    await setShowProfiles({ type: alertType, value: !showProfiles.value });
  }

  function displayProfilesAlerts(showProfiles) {
    const { type } = showProfiles;
    const profileList = new Set();
    const dataSource = cloneDeep(source);

    dataSource.forEach((profile) => {
      profile.apt_count = 0;
      const { appointments = [], inventory = [] } = profile;
      if (!profileList.has(profile)) {
        if (type === "Appointments") {
          profile.apt_count = calAlertCounts(
            appointments.map((apt) => apt.start_time)
          );
          if (profile.apt_count > 0) {
            profileList.add(profile);
          }
        }
        if (type === "Inventory") {
          if (hasInventoryAlert(inventory)) {
            profileList.add(profile);
          }
        }
      }
    });

    setDisplayList(Array.from(profileList));
  }

  function displayTypeAlerts() {
    if (!isNil(source)) {
      let aptTotal = 0;
      let inventoryAlert = false;
      const temp = cloneDeep(template);
      const dataSource = cloneDeep(source);
      dataSource.forEach((profile) => {
        const { appointments = [], inventory = [] } = profile;
        aptTotal += calAlertCounts(appointments.map((apt) => apt.start_time));
        temp[0].num = aptTotal;
        profile.apt_count = aptTotal;
        inventoryAlert = hasInventoryAlert(inventory);
        if (inventoryAlert) {
          temp[1].num++;
        }
      });
      const filteredTemp = temp.filter((item) => item.num > 0);
      setDisplayList(filteredTemp);
      if (filteredTemp.length > 0) {
        context.setHasAlert(true);
      }
    }
  }

  function calAlertCounts(arr) {
    let count = 0;
    const startMoment = moment(dateRangeFilter[0]).startOf("day");
    const endMoment = moment(dateRangeFilter[1]).endOf("day");
    arr.forEach((item) => {
      const time = moment.utc(item).local();
      if (
        startMoment.diff(time, "seconds") <= 0 &&
        endMoment.diff(time, "seconds") >= 0
      ) {
        count++;
      }
    });
    return count;
  }

  function hasInventoryAlert(arr) {
    let count = 0;
    const startMoment = moment(dateFilter).subtract(30, "day").startOf("day");
    const endMoment = moment(dateFilter).endOf("day");

    const temp = arr.filter((item) => {
      const time = moment.utc(item.adl_timestamp).local();

      return time.isBefore(endMoment);
    });

    if (isEmpty(temp)) {
      return false;
    }

    temp.forEach((item) => {
      const time = moment.utc(item.adl_timestamp).local();

      if (
        startMoment.diff(time, "seconds") <= 0 &&
        endMoment.diff(time, "seconds") >= 0
      ) {
        item.order_quantity.forEach((quantity, index) => {
          let orderName = item.order_name[index];
          let batchSize = 1;
          if (!isEmpty(orderName)) {
            const trimmed = orderName.trim();
            const productArr = trimmed.split(" - ");
            const parsedInt = parseInt(productArr[1]);
            if (isNaN(parsedInt)) {
              batchSize = 0;
            } else {
              batchSize = parsedInt;
            }
          }

          count += parseInt(quantity) * batchSize;
        });
      }
    });

    if (count < 100) {
      return true;
    }
    return false;
  }

  return (
    <View style={{ flex: 1, backgroundColor: Constant.STYLES.LIGHT_GREY }}>
      <View style={{ flex: 1 }}>
        <View style={{ flex: 1, rowGap: 15 }}>
          <View style={{ width: "100%", flexDirection: "row" }}>
            {showProfiles.value === true && (
              <View style={{ justifyContent: "center", alignItems: "center" }}>
                <View
                  style={{
                    flex: 1,
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CustomText
                    style={[
                      styles("text"),
                      { fontWeight: "bold", marginRight: 15 },
                    ]}
                  >
                    Filter by Date:
                  </CustomText>
                  {showProfiles.type === "Appointments" && (
                    <DateRangePicker
                      calendarAriaLabel="Toggle calendar"
                      clearAriaLabel="Clear value"
                      dayAriaLabel="Day"
                      monthAriaLabel="Month"
                      nativeInputAriaLabel="Date"
                      onChange={setDateRangeFilter}
                      value={dateRangeFilter}
                      yearAriaLabel="Year"
                    />
                  )}
                  {showProfiles.type === "Inventory" && (
                    <DatePicker onChange={setDateFilter} value={dateFilter} />
                  )}
                </View>
              </View>
            )}
            {showProfiles.value === false && (
              <View style={{ justifyContent: "center", alignItems: "center" }}>
                <View
                  style={{
                    flex: 1,
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CustomText style={[styles("text"), { fontWeight: "bold" }]}>
                    {!isEmpty(firstName)
                      ? `Hello! ${firstName} ${lastName} `
                      : ""}
                    Here are the alerts for Today{" "}
                    {moment().format("MMM-DD-YYYY")}
                  </CustomText>
                </View>
              </View>
            )}
            <View
              style={{
                flex: 1,
                alignItems: "flex-end",
              }}
            >
              <Pressable
                style={{
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "rgb(255, 246, 243)",
                  borderWidth: 1,
                  borderColor: "rgb(249, 198, 181)",
                  borderRadius: 30,
                  padding: 10,
                }}
                onPress={() => setHasRefresh(!hasRefresh)}
              >
                <MaterialIcons
                  name="refresh"
                  size={20}
                  color="rgb(206, 107, 74)"
                  style={{ marginRight: 5 }}
                />
                <CustomText
                  style={[
                    { paddingHorizontal: 5, color: "rgb(206, 107, 74)" },
                    styles("refreshText"),
                  ]}
                >
                  Refresh
                </CustomText>
              </Pressable>
            </View>
          </View>
          {displayList.length === 0 ? (
            <View
              style={{
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
                zIndex: -5000,
              }}
            >
              <CustomText
                style={[styles("text"), { fontWeight: "bold", fontSize: 23 }]}
              >
                There is no Alerts
              </CustomText>
            </View>
          ) : (
            <View style={{ flex: 1, zIndex: -5000 }}>
              {showProfiles.value === false ? (
                <FlatList
                  numColumns={4}
                  keyExtractor={(item) => item.alertType}
                  data={displayList}
                  renderItem={({ item }) => (
                    <CategoryCard
                      data={item}
                      cardClicked={() => showProfileClicked(item.alertType)}
                    />
                  )}
                />
              ) : (
                <FlatList
                  numColumns={4}
                  keyExtractor={(item) => item.name}
                  data={displayList}
                  renderItem={({ item }) => <ProfileCard data={item} />}
                />
              )}
            </View>
          )}
        </View>
      </View>
    </View>
  );
};

const CategoryCard = (props) => {
  const layout = useWindowDimensions();
  const styles = responsiveStyle(layout);
  const { data = {}, cardClicked } = props;
  const { num = 0, alertType = "" } = data;

  return (
    <View style={[styles("outerLayer"), GlobalCSS["small"]]}>
      <Pressable
        onPress={cardClicked}
        style={[styles("container"), { minHeight: 200 }]}
      >
        <View style={styles("topLeft")}>
          <CustomText style={[styles("text"), { color: "white" }]}>
            {num}
          </CustomText>
        </View>
        <View style={styles("title")}>
          <CustomText
            style={[styles("text"), { fontSize: 20, fontWeight: "bold" }]}
          >
            {alertType}
          </CustomText>
        </View>
      </Pressable>
    </View>
  );
};

const ProfileCard = (props) => {
  const layout = useWindowDimensions();
  const styles = responsiveStyle(layout);
  const { data } = props;
  const {
    name = "",
    email = "",
    phone = "",
    apt_count,
    inventory_count,
  } = data;

  return (
    <View
      style={[
        { height: "100%", minHeight: 200, padding: 7.5 },
        GlobalCSS["small"],
      ]}
    >
      <Pressable
        style={{
          height: "100%",
          width: "100%",
          backgroundColor: "#FAFAFA",
          borderWidth: 1,
          borderColor: "#E6E6E6",
          borderRadius: 20,
        }}
        onPress={() =>
          RootNavigation.navigate(
            Constant.NAVIGATION_OPTIONS.CUSTOMER_DETAILS.screen_name,
            { data }
          )
        }
      >
        {apt_count > 0 && (
          <View style={styles("topLeft")}>
            <CustomText style={[styles("text"), { color: "white" }]}>
              {apt_count}
            </CustomText>
          </View>
        )}
        <View
          style={{
            flex: 1,
            marginTop: 15,
            marginBottom: 15,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CustomText
            style={[
              styles("text"),
              { fontSize: 20, fontWeight: "bold", marginVertical: 10 },
            ]}
          >
            {name}
          </CustomText>
          <CustomText style={styles("text")}>{email}</CustomText>
          <CustomText style={styles("text")}>{phone}</CustomText>
        </View>
      </Pressable>
    </View>
  );
};

const responsiveStyle = CreateResponsiveStyle(
  {
    outerLayer: {
      paddingRight: 15,
    },
    container: {
      width: "100%",
      backgroundColor: "#DFE9FE",
      borderRadius: 20,
    },
    topLeft: {
      zIndex: 1,
      position: "absolute",
      width: 50,
      height: 50,
      borderRadius: 50 / 2,
      marginTop: 15,
      marginLeft: 15,
      backgroundColor: STYLES.BLUE,
      alignItems: "center",
      justifyContent: "center",
    },
    title: {
      flex: 1,
      marginTop: 15,
      marginBottom: 15,
      alignItems: "center",
      justifyContent: "center",
    },
    text: {
      fontSize: 17,
      color: "black",
    },
    refreshText: {
      fontSize: 15,
    },
  },
  {},
  {
    text: {
      fontSize: 15,
    },
    topLeft: {
      width: 40,
      height: 40,
      borderRadius: 40 / 2,
    },
    refreshText: {
      fontSize: 13,
    },
  }
);
