import React, { useEffect, useState } from "react";
import { SafeAreaView } from "react-native";
import Amplify, { Auth, Hub } from "aws-amplify";
import { cloudConfig } from "./src/service/aws-service";
import { Nunito_400Regular, useFonts } from "@expo-google-fonts/nunito";
import AppStateProvider from "./src/util/context/app-context";
import ApiService from "./src/service/api-service";
import { AuthComponent } from "./src/components/auth/auth-component";
import { Home } from "./src/components/home-component";
import "@expo/match-media";

Amplify.configure(cloudConfig);

function App() {
  const [authState, setAuthState] = useState(null);

  useEffect(() => {
    Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
          setAuthState(event);
          getUser();
          break;
        case "signOut":
          setAuthState(event);
          break;
        case "signIn_failure":
        case "cognitoHostedUI_failure":
          console.log("Sign in failure", data);
          break;
      }
    });
  }, []);

  let [fontsLoaded] = useFonts({
    "Nunito-Regular": Nunito_400Regular,
  });

  if (!fontsLoaded) {
    return null;
  }

  function getUser() {
    Auth.currentSession().then((userData) => {
      ApiService.sendToken(userData.getAccessToken().getJwtToken());
    });
  }

  return (
    <AppStateProvider>
      <SafeAreaView style={{ flex: 1 }}>
        {authState !== "signIn" && <AuthComponent />}
        {authState === "signIn" && <Home />}
      </SafeAreaView>
    </AppStateProvider>
  );
}

export default App;
