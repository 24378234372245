import React, { useContext } from "react";

import * as RootNavigation from "./RootNavigation";
import { Auth } from "aws-amplify";
import { useWindowDimensions, View } from "react-native";
import Constants from "../../util/constants";
import { Pressable } from "react-native-web-hover";
import DashboardIcon from "../common/svg/dashboard-icon";
import AlertIcon from "../common/svg/alert-icon";
import CalendarIcon from "../common/svg/calendar-icon";
import SupportIcon from "../common/svg/support-icon";
import AccountIcon from "../common/svg/account-icon";
import LogoutIcon from "../common/svg/logout-icon";
import CreateResponsiveStyle from "../../util/media-query-wrapper";
import { AppStateContext } from "../../util/context/app-context";

function renderIcon(name, fill = "white") {
  const layout = useWindowDimensions();
  const styles = responsiveStyle(layout);
  switch (name) {
    case "dashboard":
      return <AlertIcon style={styles("icon")} fill={fill} />;
    case "providers":
      return <DashboardIcon style={styles("icon")} fill={fill} />;
    case "calendar":
      return <CalendarIcon style={styles("icon")} fill={fill} />;
    case "support":
      return <SupportIcon style={styles("icon")} fill={fill} />;
    case "account":
      return <AccountIcon style={styles("icon")} fill={fill} />;
    case "logout":
      return <LogoutIcon style={styles("icon")} fill={fill} />;
    default:
      return;
  }
}

const baseColor = Constants.STYLES.BLUE;
const hoverColor = "white";

const OptionBox = (props) => {
  const layout = useWindowDimensions();
  const styles = responsiveStyle(layout);
  const { data, callBack, id, currentId } = props;

  return (
    <Pressable
      style={styles("outer")}
      onPress={() => {
        callBack(id);
        if (data.screen_name !== "logout") {
          RootNavigation.navigate(data.screen_name);
        }
      }}
    >
      {({ hovered, focused, pressed }) => (
        <View style={[styles("inner")]}>
          <View
            style={[
              {
                width: 1,
                height: "100%",
                borderRightWidth: 5,
                borderRadius: 3,
                borderColor:
                  hovered || focused || currentId === id
                    ? hoverColor
                    : baseColor,
              },
            ]}
          />
          <View style={[styles("container")]}>
            {renderIcon(data.screen_name, "white")}
          </View>
        </View>
      )}
    </Pressable>
  );
};

export const NavigationOptions = () => {
  const context = useContext(AppStateContext);
  const bottom = ["ACCOUNT"];
  const bottomList = [];

  function optionOnPressed(buttonId) {
    context.setNavigationTabActiveId(buttonId);
  }

  async function signOut() {
    try {
      await Auth.signOut({ global: true });
    } catch (error) {
      console.log("error signing out: ", error);
    }
  }

  const optionsList = [];
  Object.entries(Constants.NAVIGATION_OPTIONS).forEach(
    ([key, value], index) => {
      if (value.isShown === true) {
        if (!bottom.includes(key)) {
          optionsList.push(
            <OptionBox
              data={value}
              key={value.screen_name}
              id={index}
              callBack={optionOnPressed}
              currentId={context.navigationTabActiveId}
            />
          );
        } else {
          bottomList.push(
            <OptionBox
              data={value}
              key={value.screen_name}
              id={index}
              callBack={optionOnPressed}
              currentId={context.navigationTabActiveId}
            />
          );
        }
      }
    }
  );

  bottomList.push(
    <OptionBox
      data={{
        screen_name: "logout",
        display_name: "Log out",
      }}
      key={"logout"}
      callBack={() => signOut()}
      currentId={context.navigationTabActiveId}
    />
  );

  return (
    <View style={{ flex: 1, paddingVertical: "1rem" }}>
      <View style={{ flex: 1, rowGap: "2rem" }}>{optionsList}</View>
      <View style={{ flex: 1, justifyContent: "flex-end", rowGap: "2rem" }}>
        {bottomList}
      </View>
    </View>
  );
};

const responsiveStyle = CreateResponsiveStyle(
  {
    outer: {
      alignItems: "center",
    },
    inner: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "row",
    },
    container: {
      flex: 1,
      alignItems: "center",
    },
    hoverBar: {
      borderColor: "white",
    },
  },
  {
    icon: {
      width: "40%",
      height: "40%",
    },
  },
  {
    icon: {
      width: "35%",
      height: "35%",
    },
  }
);
