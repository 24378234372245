import React, { useContext, useEffect } from "react";
import { View } from "react-native";
import GlobalCSS from "../util/style";
import { CustomText } from "./common/custom-text-component";
import { AppStateContext } from "../util/context/app-context";
import { addRouteToChain } from "../util/method";
import Constant from "../util/constants";

export const AccountPage = ({ navigation }) => {
  const context = useContext(AppStateContext);
  const route = { name: "ACCOUNT", isHead: true };

  useEffect(() => {
    return navigation.addListener("focus", () => {
      context.setHeader("Account");
      const newChain = addRouteToChain(context.routeChain, route);
      context.setRouteChain(newChain);
    });
  }, [navigation]);

  return (
    <View style={{ flex: 1, backgroundColor: Constant.STYLES.LIGHT_GREY }}>
      <View style={[GlobalCSS.center, { flex: 1 }]}>
        <CustomText style={GlobalCSS.h1}>Account Page</CustomText>
        <CustomText style={GlobalCSS.h2}>Under Construction!</CustomText>
      </View>
    </View>
  );
};
