import React, { useContext, useState } from "react";
import { Image, ImageBackground, StyleSheet, Text, View } from "react-native";
import { SignInComponent } from "./signIn-component";
import { SignUpComponent } from "./signUp-component";
import { CustomText } from "../common/custom-text-component";
import { ResetPasswordComponent } from "./reset-password-component";
import { Overlay } from "../common/overlay-component";
import { AppStateContext } from "../../util/context/app-context";
import GlobalCSS from "../../util/style";

const authState = {
  SIGN_UP: "signUp",
  SIGN_IN: "signIn",
  CONFIRM_SIGN_UP: "confirmSignUp",
  RESET_PASSWORD: "resetPassword",
};

export const AuthComponent = () => {
  const [mode, setMode] = useState(authState.SIGN_IN);
  const [firstTime, setFirstTime] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const context = useContext(AppStateContext);

  function setModeOnPressed(mode, firstTime = false, userInfo = {}) {
    setMode(mode);
    setFirstTime(firstTime);
    setUserInfo(userInfo);
  }

  return (
    <View style={{ flex: 1 }}>
      {context.overlayState.isLoading && <Overlay />}
      <ImageBackground
        source={require("../../../assets/waves-background.jpg")}
        resizeMode="cover"
        style={{ flex: 1 }}
      >
        <View
          style={[
            GlobalCSS.center,
            {
              height: "90%",
              width: "100%",
            },
          ]}
        >
          <View style={[styles.main]}>
            <View style={{ flex: 4 }}>
              <ImageBackground
                source={require("../../../assets/login_bg_pattern.png")}
                resizeMode="cover"
                style={styles.sideImage}
              />
            </View>
            <View style={{ flex: 7 }}>
              <View
                style={[
                  GlobalCSS.center,
                  {
                    flex: 2,
                    marginVertical: 25,
                  },
                ]}
              >
                <Image
                  source={require("../../../assets/td-horizontal-logo.png")}
                  style={{
                    width: "60%",
                    height: undefined,
                    aspectRatio: 1,
                    resizeMode: "contain",
                  }}
                />
              </View>
              <View style={{ flex: 8 }}>
                {mode === authState.SIGN_IN && (
                  <SignInComponent
                    onPressSignUp={() => setModeOnPressed(authState.SIGN_UP)}
                    onPressResetPassword={(firstTime, userInfo) =>
                      setModeOnPressed(
                        authState.RESET_PASSWORD,
                        firstTime,
                        userInfo
                      )
                    }
                  />
                )}
                {mode === authState.SIGN_UP && (
                  <SignUpComponent
                    onPressSignInSubmission={() =>
                      setModeOnPressed(authState.SIGN_IN)
                    }
                  />
                )}
                {mode === authState.RESET_PASSWORD && (
                  <ResetPasswordComponent
                    onPressSignInSubmission={() =>
                      setModeOnPressed(authState.SIGN_IN)
                    }
                    firstTime={firstTime}
                    userInfo={userInfo}
                  />
                )}
              </View>
            </View>
          </View>
        </View>
        <View style={[styles.footer, GlobalCSS.center]}>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CustomText
              style={[styles.footerText, { textDecorationLine: "underline" }]}
            >
              Terms of Service
            </CustomText>
            <Text style={{ fontSize: 2, marginHorizontal: 5 }}>{"\u26AB"}</Text>
            <CustomText
              style={[styles.footerText, { textDecorationLine: "underline" }]}
            >
              Privacy Policy
            </CustomText>
            <Text style={{ fontSize: 2, marginHorizontal: 5 }}>{"\u2B24"}</Text>
            <CustomText
              style={[styles.footerText, { textDecorationLine: "underline" }]}
            >
              Cookie Policy
            </CustomText>
          </View>
          <CustomText style={styles.footerText}>
            Copyright@2022 Treasure Data, Inc (or its affiliates). All rights
            reserved.
          </CustomText>
        </View>
      </ImageBackground>
    </View>
  );
};

const styles = StyleSheet.create({
  main: {
    height: "60%",
    width: "50%",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 30,
    },
    shadowOpacity: 0.12,
    shadowRadius: 60,
    elevation: 24,
    backgroundColor: "white",
    borderRadius: 20,
    flexDirection: "row",
  },
  footer: {
    height: "10%",
    width: "100%",
  },
  footerText: {
    fontSize: 12,
    color: "#2d2d2d",
  },
  sideImage: {
    flex: 1,
    borderBottomLeftRadius: 20,
    borderTopLeftRadius: 20,
    overflow: "hidden",
  },
});
