import React, { useContext, useEffect, useState } from "react";
import { Platform, View } from "react-native";
import { AppStateContext } from "../util/context/app-context";
import { WebView } from "react-native-webview";
import { Auth } from "aws-amplify";
import { addRouteToChain } from "../util/method";
import Constant from "../util/constants";

export const CalendarPage = ({ navigation }) => {
  const [userInfo, setUserInfo] = useState("");
  const context = useContext(AppStateContext);
  const route = { name: "CALENDAR", isHead: true };
  const isWeb = Platform.OS === "web";

  useEffect(() => {
    async function getUserInfo() {
      try {
        const result = await Auth.currentUserInfo();
        setUserInfo(result.attributes.email);
      } catch (error) {
        console.log(error);
      }
    }

    getUserInfo();
  }, []);

  useEffect(() => {
    return navigation.addListener("focus", () => {
      context.setHeader("Calendar");
      const newChain = addRouteToChain(context.routeChain, route);
      context.setRouteChain(newChain);
    });
  }, [navigation]);

  const url = `https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23ffffff&ctz=America%2FLos_Angeles&showTitle=0&src=${encodeURI(
    userInfo
  )}&color=%23039BE5&color=%230B8043`;
  return (
    <View style={{ flex: 1, backgroundColor: Constant.STYLES.LIGHT_GREY }}>
      <View style={[{ flex: 1 }]}>
        {!isWeb && (
          <WebView
            style={{ flex: 1 }}
            originWhitelist={["*"]}
            source={{
              html:
                "<iframe src=" +
                `${url}` +
                ' width="100%" height="100%"></iframe>',
            }}
          />
        )}
        {isWeb && <iframe src={url} width="100%" height="100%" />}
      </View>
    </View>
  );
};
