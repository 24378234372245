import * as React from "react"
import Svg, { Circle, Path } from "react-native-svg"

const ProfileIcon = (props) => (
    <Svg
        width={60}
        height={60}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 60 60"
        {...props}
    >
        <Circle cx={30} cy={30} fill="#F8EAC5" r={30} />
        <Path
            d="M49.691 34.232c-.14-2.168-.769-4.267-1.888-6.086l-.91-2.448-9.583-3.428-14.97 3.428-10.423 7.835.14 3.078c.56 8.744 7.485 15.32 19.797 15.32C43.675 52 50.25 42.975 49.69 34.231Z"
            fill="#fff"
        />
        <Path
            d="M46.892 19.962c-.49-4.547-8.464-11.682-16.368-10.493-.49-.56-.98-.91-1.33-1.19-.35-.279-.769-.349-1.119-.209l-7.625 2.098c-.77.21-1.189.98-.979 1.68l.14.35c-3.498 1.468-9.514 7.134-9.514 14.34l.28-.07a37.71 37.71 0 0 0-2.308 2.518l1.609 7.695c3.917-8.045 14.9-11.473 21.825-13.012 6.575-1.469 15.46 0 18.188 4.687l.35-6.296c-.77-.7-1.82-1.399-3.148-2.098Z"
            fill="#F3DAB0"
        />
        <Path
            d="M47.803 28.146c1.05 1.819 1.75 3.917 1.889 6.086C50.252 42.976 43.676 52 31.924 52c-12.312 0-19.237-6.575-19.797-15.32"
            stroke="#494F61"
            strokeWidth={1.491}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <Path
            d="M19.192 30.455c2.868-1.05 5.666-.49 6.296 1.189.63 1.749-1.26 3.917-4.128 4.897-2.868 1.049-5.666.49-6.295-1.19-.63-1.609 1.259-3.847 4.127-4.896ZM41.577 26.887c3.078 0 5.456 1.54 5.456 3.358 0 1.819-2.518 3.288-5.596 3.148-3.078 0-5.456-1.54-5.456-3.358 0-1.819 2.518-3.148 5.596-3.148Z"
            fill="#EA0000"
            fillOpacity={0.23}
        />
        <Path
            d="M21.64 28.916v1.958M39.689 25.698v1.959M26.817 40.598c1.05 2.378 5.107 3.498 8.255.98M9.679 36.75c3.917-7.554 14.9-10.842 21.825-12.241 6.576-1.4 15.46 0 18.188 4.407l.35-5.946c-3.638-2.938-12.242-6.226-20.217-5.037C22.9 18.983 12.897 23.6 8 29.476l1.679 7.275Z"
            stroke="#494F61"
            strokeWidth={1.382}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <Path
            d="M33.673 17.863c-.49-5.806-4.967-8.254-4.967-8.254l-7.765 1.679s3.638 4.267 3.498 7.625"
            stroke="#494F61"
            strokeWidth={1.382}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <Path
            d="M46.964 21.081c-.35-4.267-8.324-11.192-16.369-10.003M19.612 13.596c-3.497 1.33-9.513 6.786-9.513 13.501"
            stroke="#494F61"
            strokeWidth={1.382}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </Svg>
)

export default ProfileIcon
