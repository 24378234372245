import React, { useEffect, useState } from "react";
import { StyleSheet } from "react-native";
import DropDownPicker from "react-native-dropdown-picker";

export const DropdownMultipleComponent = (props) => {
  const {
    dropdownList = [],
    style = {},
    showTick = false,
    setSelected,
  } = props;
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState([]);
  const [items, setItems] = useState(dropdownList);
  const [multiText, setMultiText] = useState("");

  function setMultiPlaceHolder() {
    const placeHolder = [];
    dropdownList.map((month) =>
      month.isSelected === true ? placeHolder.push(month.label) : {}
    );
    return placeHolder.join(", ");
  }

  useEffect(() => {
    setDefaultValue();
    setItems(dropdownList);
    setMultiText(setMultiPlaceHolder());
  }, [dropdownList]);

  function setDefaultValue() {
    const defaultList = [];
    dropdownList.forEach((month) => {
      if (month.isSelected === true) {
        defaultList.push(month.value);
      }
    });

    setValue(defaultList);
  }

  function selectionCallBack(list) {
    items.forEach((item) => {
      list.includes(item.value)
        ? (item.isSelected = true)
        : (item.isSelected = false);
    });
    setSelected(items);
    setMultiText(setMultiPlaceHolder());
  }

  return (
    <DropDownPicker
      open={open}
      showTickIcon={showTick}
      min={0}
      max={12}
      value={value}
      items={items}
      multiple={true}
      setOpen={setOpen}
      setValue={setValue}
      setItems={setItems}
      style={[styles.dropdown, { ...style }]}
      closeAfterSelecting={true}
      listItemLabelStyle={{ fontSize: 14 }}
      dropDownContainerStyle={{
        width: "100%",
        alignItems: "center",
        borderWidth: 2,
        paddingRight: 20,
        borderColor: "#9d9d9d",
        rowGap: "1rem",
        zIndex: 1,
        maxHeight: null,
        paddingVertical: "1rem",
      }}
      multipleText={multiText}
      placeholder={"Select Months"}
      onChangeValue={(value) => {
        selectionCallBack(value);
      }}
      listItemContainerStyle={{
        flexDirection: "row",
        width: "100%",
        marginVertical: 2,
      }}
      selectedItemLabelStyle={{
        fontWeight: "bold",
      }}
    />
  );
};

const styles = StyleSheet.create({
  dropdown: {
    backgroundColor: "white",
    width: "100%",
    flexDirection: "row",
    textAlign: "center",
    borderRadius: 10,
    lineHeight: 3,
    textAlignVertical: "center",
    padding: 10,
    marginRight: 15,
    zIndex: 1,
    borderColor: "#9d9d9d",
    borderWidth: 2,
    fontWeight: "bold",
  },
});
