import * as React from "react"
import Svg, { Path } from "react-native-svg"

const CalendarIcon = (props) => (
    <Svg
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 17.42 17.42"
        {...props}
    >
        <Path d="M15.86 1.56h-3.15V.31a.31.31 0 1 0-.62 0v2.8a.31.31 0 0 0 .62 0v-.93h3.11a.94.94 0 0 1 .94.93v2.8H.62v-2.8a.94.94 0 0 1 .94-.93h2.49v-.62H1.56A1.56 1.56 0 0 0 0 3.11v12.75a1.56 1.56 0 0 0 1.56 1.56h14.3a1.56 1.56 0 0 0 1.56-1.56V3.11a1.55 1.55 0 0 0-1.56-1.55Zm.94 14.3a.94.94 0 0 1-.94.94H1.56a.94.94 0 0 1-.94-.94V6.53H16.8v9.33Z" />
        <Path d="M4.98 3.42a.31.31 0 0 0 .31-.31v-.93h6.22v-.62H5.29V.31a.31.31 0 1 0-.58 0v2.8a.31.31 0 0 0 .27.31Z" />
    </Svg>
)

export default CalendarIcon
