import React from "react";
import { View } from "react-native";
import moment from "moment";
import { CustomText } from "../custom-text-component";
import Timeline from "react-native-timeline-flatlist";
import Constants from "../../../util/constants";
import DesktopIcon from "../svg/desktop-icon";
import MobileIcon from "../svg/mobile-icon";

export const PageViewTimelineComponent = (props) => {
  const { data } = props;

  const iconType = {
    "Apple Desktop": <DesktopIcon width="20px" />,
    "Apple Mobile": <MobileIcon width="20px" />,
  };

  const content = [];
  data.forEach((entry) => {
    const {
      device_category = "Apple Desktop",
      path = "",
      timestamp = new Date(),
    } = entry;
    const time = moment.utc(timestamp).local();
    content.push({
      title: (
        <CustomText>
          {time.format("ll")} {time.format("LT")}
        </CustomText>
      ),
      description: (
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <View style={{ width: "2%", marginRight: 5, alignItems: "flex-end" }}>
            {iconType[device_category]}
          </View>
          <CustomText style={{ fontSize: 15 }}>
            {device_category}: {path}
          </CustomText>
        </View>
      ),
    });
  });

  return (
    <Timeline
      style={{ flex: 1 }}
      detailContainerStyle={{ marginBottom: 15 }}
      circleColor={Constants.STYLES.BLUE}
      lineColor={Constants.STYLES.BLUE}
      timeContainerStyle={{ flex: 1 }}
      titleStyle={{ marginBottom: 10 }}
      data={content}
      eventContainerStyle={{ marginTop: -5 }}
    />
  );
};
