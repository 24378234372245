import React from "react";
import { StyleSheet, View } from "react-native";
import { createStackNavigator } from "@react-navigation/stack";
import { NavigationContainer } from "@react-navigation/native";
import * as RootNavigation from "./RootNavigation";
import Constants from "../../util/constants";

import { DashboardPage } from "../dashboard-page";
import { AccountPage } from "../account-page";
import { CalendarPage } from "../calendar-page";
import { SupportPage } from "../support-page";
import { ProvidersPage } from "../providers-page";
import { CustomerProfilePage } from "../customer-profile-page";

const Stack = createStackNavigator();

const NavigationOptionsContainer = () => {
  return (
    <NavigationContainer ref={RootNavigation.navigationRef}>
      <View style={styles.mainContainer}>
        <View style={styles.container}>
          <Stack.Navigator
            screenOptions={{ headerShown: false, animationEnabled: false }}
          >
            <Stack.Screen
              name={Constants.NAVIGATION_OPTIONS.DASHBOARD.screen_name}
              component={DashboardPage}
              options={{
                title: Constants.NAVIGATION_OPTIONS.DASHBOARD.display_name,
              }}
            />
            <Stack.Screen
              name={Constants.NAVIGATION_OPTIONS.ACCOUNT.screen_name}
              component={AccountPage}
              options={{
                title: Constants.NAVIGATION_OPTIONS.ACCOUNT.display_name,
              }}
            />
            <Stack.Screen
              name={Constants.NAVIGATION_OPTIONS.CALENDAR.screen_name}
              component={CalendarPage}
              options={{
                title: Constants.NAVIGATION_OPTIONS.CALENDAR.display_name,
              }}
            />
            <Stack.Screen
              name={Constants.NAVIGATION_OPTIONS.SUPPORT.screen_name}
              component={SupportPage}
              options={{
                title: Constants.NAVIGATION_OPTIONS.SUPPORT.display_name,
              }}
            />
            <Stack.Screen
              name={Constants.NAVIGATION_OPTIONS.PROVIDERS.screen_name}
              component={ProvidersPage}
              options={{
                title: Constants.NAVIGATION_OPTIONS.PROVIDERS.display_name,
              }}
            />
            <Stack.Screen
              name={Constants.NAVIGATION_OPTIONS.CUSTOMER_DETAILS.screen_name}
              component={CustomerProfilePage}
              options={{
                title:
                  Constants.NAVIGATION_OPTIONS.CUSTOMER_DETAILS.display_name,
              }}
            />
          </Stack.Navigator>
        </View>
      </View>
    </NavigationContainer>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 38,
  },
  containerFooter: {
    height: 15,
  },
  mainContainer: {
    flex: 1,
    backgroundColor: Constants.STYLES.LIGHT_GREY,
    borderRadius: 20,
  },
});

export default NavigationOptionsContainer;
