import React from "react";
import { View } from "react-native";
import moment from "moment";
import { CustomText } from "../custom-text-component";
import Timeline from "react-native-timeline-flatlist";
import Constants from "../../../util/constants";
import { isNil } from "lodash";

export const DownloadHistoryTimelineComponent = (props) => {
  const { data } = props;

  const content = [];
  const duplicationMap = {};
  data.forEach((entry) => {
    const { timestamp = new Date() } = entry;
    const time = moment.utc(timestamp).local();
    const link = entry.link;

    if (isNil(duplicationMap[link])) {
      duplicationMap[link] = 0;
    }
    duplicationMap[link]++;

    content.push({
      title: (
        <CustomText>
          {time.format("ll")} {time.format("LT")}
        </CustomText>
      ),
      description: (
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <CustomText style={{ fontSize: 15, marginRight: 10 }}>
            {link}
          </CustomText>
          {duplicationMap[link] > 1 && (
            <View
              style={{
                backgroundColor: "rgb(255, 240, 240)",
                borderColor: "rgb(182, 53, 53)",
                borderWidth: 1,
                width: 22,
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "0.75rem",
              }}
            >
              <CustomText>{duplicationMap[link]}</CustomText>
            </View>
          )}
        </View>
      ),
    });
  });

  return (
    <Timeline
      style={{ flex: 1 }}
      detailContainerStyle={{ marginBottom: 15 }}
      circleColor={Constants.STYLES.BLUE}
      lineColor={Constants.STYLES.BLUE}
      titleStyle={{ marginBottom: 10 }}
      data={content}
      eventContainerStyle={{ marginTop: -5 }}
    />
  );
};
