import React, { useContext } from "react";
import { StyleSheet, useWindowDimensions, View } from "react-native";
import { Overlay } from "./common/overlay-component";
import { NavigationOptions } from "./navigation/navigation-options";
import NavigationOptionsContainer from "./navigation/navigation-options-container";
import { AppStateContext } from "../util/context/app-context";
import HeaderComponent from "./common/header-component";
import Constant from "../util/constants";
import LogoWhiteIcon from "./common/svg/logo-white-icon";
import CreateResponsiveStyle from "../util/media-query-wrapper";

const { STYLES } = Constant;
const { LIGHT_GREY, BLUE } = STYLES;

export const Home = () => {
  const context = useContext(AppStateContext);
  const layout = useWindowDimensions();
  const styles = responsiveStyle(layout);

  return (
    <View style={{ flex: 1 }}>
      {context.overlayState.isLoading && <Overlay />}
      <View style={styles("background")}>
        <View style={styles("leftContainer")}>
          <View style={[{ flex: 2 }, styles("center")]}>
            <LogoWhiteIcon style={styles("logo")} />
          </View>
          <View style={styles("navigation")}>
            <NavigationOptions />
          </View>
        </View>
        <View style={styles("mainContainer")}>
          <View style={{ flex: 2 }}>
            <HeaderComponent />
          </View>
          <View style={{ flex: 27 }}>
            <NavigationOptionsContainer />
          </View>
        </View>
      </View>
      {/* <View style={styles.footer} /> */}
    </View>
  );
};

const responsiveStyle = CreateResponsiveStyle(
  {
    background: {
      flex: 1,
      padding: "1rem",
      backgroundColor: LIGHT_GREY,
      flexDirection: "row",
    },
    leftContainer: {
      backgroundColor: BLUE,
      width: "4%",
      borderRadius: 30,
      paddingVertical: "1rem",
      marginRight: "1rem",
    },
    mainContainer: {
      flex: 1,
    },
    center: {
      alignItems: "center",
      justifyContent: "center",
    },
    footer: {
      height: 15,
    },
    navigation: {
      flex: 27,
      backgroundColor: BLUE,
      borderRadius: 30,
    },
    logo: {
      width: "100px",
      height: "100px",
    },
  },
  {},
  {
    logo: {
      width: "70px",
      height: "70px",
    },
  }
);
