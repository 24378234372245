import React, { useContext } from "react";
import { Pressable, useWindowDimensions, View } from "react-native";
import { CustomText } from "../common/custom-text-component";
import Constants from "../../util/constants";
import * as RootNavigation from "./RootNavigation";
import { AppStateContext } from "../../util/context/app-context";
import { get } from "lodash";
import CreateResponsiveStyle from "../../util/media-query-wrapper";

export const NavigationTabs = () => {
  const context = useContext(AppStateContext);
  const layout = useWindowDimensions();
  const styles = responsiveStyle(layout);
  let { routeChain } = context;
  const tabs = [];

  function tabClicked(index) {
    const temp = routeChain.slice(0, index + 1);
    context.setRouteChain(temp);
  }

  if (routeChain.length > 1) {
    routeChain.forEach((route, index) => {
      const target = Constants.NAVIGATION_OPTIONS[route.name];

      if (index === routeChain.length - 1) {
        tabs.push(
          <CustomText
            style={[
              styles("text"),
              styles("currentTab"),
              { fontWeight: "bold" },
            ]}
            key={index}
          >
            {route.title || target.display_name}
          </CustomText>
        );
      } else {
        tabs.push(
          <Pressable
            key={index}
            onPress={() => {
              tabClicked(index);
              RootNavigation.navigate(target.screen_name, {
                data: get(route, "params.data") || {},
              });
            }}
          >
            <CustomText style={[styles("text")]}>
              {route.title || target.display_name}
            </CustomText>
          </Pressable>
        );
      }

      if (index < routeChain.length - 1) {
        tabs.push(
          <CustomText
            style={[styles("text"), styles("symbol")]}
            key={`${index}>`}
          >
            {">"}
          </CustomText>
        );
      }
    });
  }

  return (
    <View
      style={{ flexDirection: "row", alignItems: "center", marginVertical: 5 }}
    >
      {tabs}
    </View>
  );
};

const responsiveStyle = CreateResponsiveStyle(
  {
    text: {
      fontSize: 13,
    },
    symbol: {
      marginLeft: 10,
      marginRight: 10,
    },
    currentTab: {
      color: "#C8876F",
    },
  },
  {},
  {
    text: {
      fontSize: 11,
    },
  }
);
