import React, { useContext, useEffect, useState } from "react";
import {
  FlatList,
  Platform,
  Pressable,
  TextInput,
  useWindowDimensions,
  View,
} from "react-native";
import { CustomText } from "./common/custom-text-component";
import { Feather, MaterialIcons } from "@expo/vector-icons";
import GlobalCSS from "../util/style";
import { AppStateContext } from "../util/context/app-context";
import * as RootNavigation from "./navigation/RootNavigation";
import Constant from "../util/constants";
import { addRouteToChain } from "../util/method";
import ApiService from "../service/api-service";
import CreateResponsiveStyle from "../util/media-query-wrapper";
import { isNil, isString } from "lodash";

export const ProvidersPage = (props) => {
  const { navigation } = props;
  const route = { name: "PROVIDERS", isHead: true };
  const [source, setSource] = useState([]);
  const [hasRefresh, setHasRefresh] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const layout = useWindowDimensions();
  const styles = responsiveStyle(layout);
  const context = useContext(AppStateContext);

  useEffect(() => {
    return navigation.addListener("focus", () => {
      context.setHeader(`Provider List`);
      const newChain = addRouteToChain(context.routeChain, route);
      context.setRouteChain(newChain);
    });
  }, [navigation]);

  useEffect(() => {
    getData();
  }, [hasRefresh]);

  async function getData() {
    try {
      context.setOverlayState(true);
      console.log("UI - API called to get ProfileList");
      const result = await ApiService.getProfileList();
      setSource(result);
      context.setOverlayState(false);
    } catch (error) {
      context.setOverlayState(true, error.toString());
    }
  }

  function editSearchTerm(text) {
    setSearchTerm(text);
  }

  function dynamicSearch() {
    let temp = [];
    if (!isNil(source)) {
      temp = source.filter((entry) => {
        let text = [];
        Object.keys(entry).forEach((key) => {
          if (
            (key === "email" ||
              key === "phone" ||
              key === "name" ||
              key === "td_client_id") &&
            isString(entry[key])
          ) {
            text.push(entry[key].toLowerCase());
          }
        });
        return text.join("*_*").includes(searchTerm.toLowerCase());
      });
    }
    return temp;
  }

  return (
    <View
      style={{
        flex: 1,
        backgroundColor: Constant.STYLES.LIGHT_GREY,
      }}
    >
      <View style={{ flex: 1, rowGap: 15 }}>
        <View
          style={{
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <View style={{ flex: 1, marginRight: 7.5 }} />
          <View style={styles("searchBar")}>
            <Feather
              name="search"
              size={20}
              color="black"
              style={{ marginLeft: 1 }}
            />
            <TextInput
              style={[
                {
                  fontSize: 20,
                  paddingLeft: 10,
                  marginLeft: 10,
                  width: "100%",
                },
                Platform.OS === "web" && { outlineStyle: "none" },
              ]}
              value={searchTerm}
              placeholder="Search"
              onChangeText={(text) => editSearchTerm(text)}
            />
          </View>
          <View
            style={{
              flex: 1,
              height: "100%",
              alignItems: "flex-end",
              marginLeft: 7.5,
              paddingRight: 7.5,
            }}
          >
            <Pressable
              style={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgb(255, 246, 243)",
                borderWidth: 1,
                borderColor: "rgb(249, 198, 181)",
                borderRadius: 30,
                padding: 10,
              }}
              onPress={() => setHasRefresh(!hasRefresh)}
            >
              <MaterialIcons
                name="refresh"
                size={20}
                color="rgb(206, 107, 74)"
                style={{ marginRight: 5 }}
              />
              <CustomText
                style={[
                  { paddingHorizontal: 5, color: "rgb(206, 107, 74)" },
                  styles("refreshText"),
                ]}
              >
                Refresh
              </CustomText>
            </Pressable>
          </View>
        </View>
        <View style={styles("body")}>
          <FlatList
            numColumns={4}
            keyExtractor={(item) => item.email}
            data={dynamicSearch()}
            renderItem={({ item }) => <ProfileCardComponent data={item} />}
          />
        </View>
      </View>
    </View>
  );
};

const ProfileCardComponent = (props) => {
  const layout = useWindowDimensions();
  const styles = responsiveStyle(layout);
  const { data } = props;
  const { name = "", email = "", phone = "" } = data;

  return (
    <View style={[styles("outerLayer"), GlobalCSS["small"]]}>
      <Pressable
        style={[styles("container"), { height: "100%" }]}
        onPress={() =>
          RootNavigation.navigate(
            Constant.NAVIGATION_OPTIONS.CUSTOMER_DETAILS.screen_name,
            { data }
          )
        }
      >
        <View style={styles("title")}>
          <CustomText
            style={[
              styles("text"),
              { fontSize: 20, fontWeight: "bold", marginVertical: 10 },
            ]}
          >
            {name}
          </CustomText>
          <CustomText style={styles("text")}>{email}</CustomText>
          <CustomText style={styles("text")}>{phone}</CustomText>
        </View>
      </Pressable>
    </View>
  );
};

const responsiveStyle = CreateResponsiveStyle(
  {
    searchBar: {
      padding: 10,
      marginHorizontal: 15,
      flexDirection: "row",
      flex: 2,
      borderColor: Constant.STYLES.BLUE,
      borderRadius: 15,
      alignItems: "center",
      borderWidth: 2,
    },
    body: {
      flex: 1,
    },
    outerLayer: {
      height: "100%",
      minHeight: 200,
      padding: 7.5,
    },
    container: {
      width: "100%",
      backgroundColor: "#FAFAFA",
      borderWidth: 1,
      borderColor: "#E6E6E6",
      borderRadius: 20,
    },
    title: {
      flex: 1,
      marginTop: 15,
      marginBottom: 15,
      alignItems: "center",
      justifyContent: "center",
    },
    text: {
      fontSize: 14,
      color: "black",
    },
    refreshText: {
      fontSize: 17,
    },
  },
  {},
  {
    refreshText: {
      fontSize: 15,
    },
  }
);
