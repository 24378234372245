import { isArray, cloneDeep, isNil, isObject } from "lodash";
import moment from "moment";

export function convertStringToBoolean(string) {
  if (typeof string === "string") {
    if (JSON.parse(string.toLowerCase()) === true) {
      return true;
    }
    if (JSON.parse(string.toLowerCase()) === false) {
      return false;
    }
  }

  return undefined;
}

export function addRouteToChain(chain, inputRoute) {
  let temp = cloneDeep(chain);

  if (inputRoute.isHead) {
    temp = [inputRoute];
  } else {
    temp.push(inputRoute);
  }

  return temp;
}

export function appendObject(target, obj, key) {
  if (isNil(target[key])) {
    target[key] = {};
    return target[key];
  }

  return obj !== null ? (target[key] = obj) : target[key];
}

export function appendArray(target, obj, key) {
  if (isNil(target[key])) {
    target[key] = [obj];
    return target[key];
  }

  if (obj !== null) {
    target[key].push(obj);
  }

  return target[key];
}

export function sortByDate(obj) {
  if (isArray(obj)) {
    obj.sort((a, b) => {
      const aTime = moment(a.timestamp);
      const bTime = moment(b.timestamp);

      if (aTime.isAfter(bTime)) {
        return 1;
      }
      if (aTime.isBefore(bTime)) {
        return -1;
      }

      return 0;
    });
  }

  if (isObject(obj)) {
    Object.values(obj).forEach((value) => {
      if (isObject(value)) {
        sortByDate(value);
      }
    });
  }
}

export function beautifyKey(string) {
  const tempArr = [];
  string.split("_").forEach((word) => {
    tempArr.push(word.toLowerCase());
  });
  tempArr[0] = tempArr[0].charAt(0).toUpperCase() + tempArr[0].slice(1);
  return tempArr.join(" ");
}

export function isJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}
