import React from "react";
import { Image, View } from "react-native";
import moment from "moment";
import { CustomText } from "../custom-text-component";
import Timeline from "react-native-timeline-flatlist";
import Constants from "../../../util/constants";
import ProfileIcon from "../svg/profile-icon";
import { get } from "lodash";

export const ChatHistoryTimelineComponent = (props) => {
  const { data } = props;

  const content = [];
  data.forEach((entry) => {
    const { chat = [], timestamp = new Date() } = entry;
    const time = moment.utc(timestamp).local();
    content.push({
      title: (
        <CustomText>
          {time.format("ll")} {time.format("LT")}
        </CustomText>
      ),
      description: chat.map((line, index) => {
        const { actor = "", timestamp = new Date() } = line;
        return (
          <View
            key={index}
            style={{
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <CustomText style={{ fontSize: 13, marginRight: 10 }}>
                {moment(timestamp).local().format("h:mm:ss A")}
              </CustomText>
              {actor === "user" && (
                <View style={{ marginRight: 10 }}>
                  <ProfileIcon width="50" />
                </View>
              )}
              {actor === "bot" && (
                <View style={{ width: "50", marginRight: 10 }}>
                  <Image
                    source={require("../../../../assets/bot.png")}
                    style={{
                      width: 50,
                      height: undefined,
                      aspectRatio: 1,
                      resizeMode: "contain",
                    }}
                  />
                </View>
              )}
            </View>
            <CustomText style={{ fontSize: 15 }}>
              {get(line, "message.text") ||
                get(line, "message.attachment.payload.text") ||
                "Cant read this line"}
            </CustomText>
          </View>
        );
      }),
    });
  });

  return (
    <Timeline
      style={{ flex: 1 }}
      detailContainerStyle={{ marginBottom: 15 }}
      circleColor={Constants.STYLES.BLUE}
      lineColor={Constants.STYLES.BLUE}
      timeContainerStyle={{ flex: 1 }}
      titleStyle={{ marginBottom: 10 }}
      data={content}
      eventContainerStyle={{ marginTop: -5 }}
    />
  );
};
