import awsconfig from "../aws-exports";
import { Linking, Platform } from "react-native";
import * as WebBrowser from "expo-web-browser";

const expoSignInUrl = "http://localhost:19006/";
const prodSignInUrl = "https://www.treasuredata-cdpforlifescience.com/";

const urlOpener = async function urlOpener(url, redirectUrl) {
  const { type, url: newUrl } = await WebBrowser.openAuthSessionAsync(
    url,
    redirectUrl
  );

  if (type === "success" && Platform.OS === "ios") {
    WebBrowser.dismissBrowser();
    return Linking.openURL(newUrl);
  }
};

if (Platform.OS === "web") {
  const isLocalhost = Boolean(
    window.location.hostname === "localhost" ||
      // [::1] is the IPv6 localhost address.
      window.location.hostname === "[::1]" ||
      // 127.0.0.1/8 is considered localhost for IPv4.
      window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
      )
  );

  if (!isLocalhost) {
    awsconfig.oauth = {
      ...awsconfig.oauth,
      redirectSignIn: prodSignInUrl,
      redirectSignOut: prodSignInUrl,
    };
  }
}

if (Platform.OS === "ios") {
  awsconfig.oauth = {
    ...awsconfig.oauth,
    redirectSignIn: expoSignInUrl,
    redirectSignOut: expoSignInUrl,
    urlOpener,
  };
}

export const cloudConfig = awsconfig;
